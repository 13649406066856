.event-details-page {
    margin-top: 40px;
    & .event-details-heading {
        padding: 24px;
        display: flex;
        flex-direction: row;
        align-items: top;
        justify-content: space-between;
        & .grid-left {
            display: flex;
            flex-direction: row;
            align-items: top;
            .event-details-title {
                font-family: "Zen Dots", serif;
                font-size: var(--text-xxl);
                margin: 0 24px;
            }
        }

        .event-actions {
            .save-event-btn {
                border: 1px solid var(--cyan);
                border-radius: 99%;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    & .event-deets-main {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        .event-deets-content {
            padding: 0 82px 24px;

            & .deets-image {
                width: 24px;
            }

            & .deets-row {
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                gap: 10px;

                & .deets-texts {
                    border-left: 0.5px solid var(--text-grey);
                    padding-left: 16px;
                }
            }

            & .deets-row-two {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;

                & .deets-row-two-label {
                    font-size: var(--text-sm);
                    color: var(--text-grey);
                    font-weight: 400;
                    margin: 0;
                }

                & .deets-row-two-value {
                    font-size: var(--text-md);
                    font-weight: 400;
                }
            }

            & .event-deets-description {
                margin: 24px 0;
                padding: 16px 24px;
                border-radius: 12px;
                background-color: var(--black-20);
                border: 1px solid var(--cyan-10);
            }

            & .deets-label {
                font-size: var(--text-sm);
                color: var(--text-grey);
                font-weight: 800;
                margin: 0;
            }

            & .deets-value {
                font-size: var(--text-base);
                font-weight: 600;
                margin: 8px 0 0;
            }

            & .event-genres {
                display: flex;
                gap: 8px;
            } 

            & .performers-names { 
                font-family: "Zen Dots", serif;
                color: var(--sand-beige); 
            }
        }

        .event-poster-and-cta {
            .event-deets-poster {
                margin: 0 24px 0;
                min-width: 600px;
                max-width: 600px;
                min-height: fit-content;
                max-height: fit-content;
                border-radius: 12px;
                box-shadow: -16px 16px 0 2px rgba(0,0,0,0.5);
    
                & img {
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                }
            }

            & .get-tix-btn {
                margin: 48px auto 0;
                width: 200px;
            }
        }

        
        
    }
}