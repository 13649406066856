.comment-section {
    & .single-comment {
        .commented-by {

            & .commented-by-image {

            }

            & .comment-and-replies {
                width: 85%;
                & .commented-by-details {
                    
                    position: relative;
                    & .commented-by-user {
                        
                        .name-and-dots {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            padding: 4px 0;
    
                            & .with-timestamp {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
    
                                & .commented-by-name {
                                    padding: 0;
                                }
    
                                & .commented-by-date {
                                    padding: 2px 8px 0;
                                }
                            }

                            & .comment-more-icon {
                                position: relative;

                                & .options-modal {
                                    width: 160px;
                                    position: absolute;
                                    right: 2px;
                                    top: 20px;
                                    color: #fff;
                                    z-index: 99;
                                
                                    background-color: var(--dark-blue);
                                    box-shadow: 10px 0 20px -9px rgba(0,0,0,0.5);
                                    border-radius: 10px;
                                    overflow: hidden;

                                    .comment-options-modal {

                                        & .comment-options-list {
                                
                                            list-style: none;
                                            padding: 0;
                                            margin: 0;
                                
                                            & .comment-option-li {
                                                margin: 0;
                                                padding: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: start;
                                                gap: 8px;

                                                & p {
                                                    padding: 0;
                                                    font-size: var(--text-md);
                                                }

                                                &:hover {
                                                    background-color: var(--black-20);
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            
                        }
                    }
                    
                    & .comment-body {
                        & .comment-text {
                            padding-bottom: 28px;
                        }
                    }


                    & .comment-body {
                        & .comment-text {
                            padding-bottom: 28px;
                        }
                    }

                    & .like-comment {
                        background: rgba(255, 255, 255, 0.1);
                        display: flex;
                        gap: 12px;
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        padding: 8px 14px;
                        border-radius: 20px 0 10px 0;

                        & .like-button {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 4px;

                            & .like-count {
                                font-size: 12px;
                                padding: 0;
                            }
                        }
                    }
                }

                .replies-section {
                    width: 100%;
                    margin: 0 0 0 20px;
                    border-left: 3px solid var(--black-10); /* Visual separation */
                    border-radius: 4px;
                    .single-reply {
                        display: flex;
                        gap: 8px;
                        margin-top: 8px;
                        padding: 8px 16px 32px;
                        background-color: var(--black-05);
                        & .user-img {
                            width: 40px;
                            height: 40px;
                            min-width: 40px;
                            min-height: 40px;
                            border-radius: 99px;
                            overflow: hidden;

                            & img {
                                width: 100%;
                            }
                        }
                        & .reply-by {
                            display: block;
                            width: 100%;
                            & .name-and-dots {

                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: space-between;
                                padding: 4px 0;
    
                                & .with-timestamp {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
        
                                    & .reply-by-name {
                                        padding: 0;
                                    }
        
                                    & .reply-by-date {
                                        padding: 2px 8px 0;
                                    }
                                }

                                .reply-more-icon {
                                    position: relative;

                                    .reply-options-modal {
                                        width: 160px;
                                        position: absolute;
                                        right: 2px;
                                        top: 20px;
                                        color: #fff;
                                        z-index: 99;
                                        padding: 0;
                                        margin: 0;
                                    
                                        background-color: var(--dark-blue);
                                        box-shadow: 10px 0 20px -9px rgba(0,0,0,0.5);
                                        border-radius: 10px;
                                        overflow: hidden;

                                        & .reply-options-list {
                                
                                            list-style: none;
                                            padding: 0;
                                            margin: 0;
                                
                                            & .reply-option-li {
                                                margin: 0;
                                                padding: 12px;
                                                display: flex;
                                                flex-direction: row;
                                                align-items: center;
                                                justify-content: start;
                                                gap: 8px;

                                                & p {
                                                    padding: 0;
                                                    font-size: var(--text-md);
                                                }

                                                &:hover {
                                                    background-color: var(--black-20);
                                                    cursor: pointer;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    
}