.minimized-menu {
	justify-content: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	& .link-to-profile {
		& .user-avatar {
			width: 36px;
			height: 36px;
			border-radius: 99px;
			overflow: hidden;

			& img {
				width: 100%;
				object-fit: cover;
			}
		}

		& p {
			text-align: center;
			font-size: var(--text-md);
			font-weight: bold;
		}
	}
	
}