.merch-details-overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	padding: 16px;
    width: 500px;
	animation: fadeSlideInRight 0.3s ease-out forwards; /* Apply the animation */

	& .merch-details-card {
		background-color: var(--deep-blue);
	    padding: 8px 16px;
        border-radius: 12px;
        overflow: scroll;
		.merch-details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

		& .merch-details-content {

			& .merch-thumbnail {
				/* width: 400px;
				min-width: 400px;
				height: 400px;
				min-height: 400px; */


				& img {
					width: 100%;
					height: 100%;
					border-radius: 8px;
				}
			}

			& h3 { 
				margin: 12px 0;
			}

			& .merch-description {
				font-size: var(--text-md);
				color: var(--faded-white);
			}
		}
	}
}