.account-settings {
	height: 96vh;
	padding-top: 30px;

	.account-settings-container {
		display: flex;
		height: 100%;

		.settings-side-menu {
			margin: auto 24px;

			.sidemenu-header {
				font-weight: bold;
				font-size: var(--text-md);
			}

			.settings-menu-item {
				
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				border-radius: 99px;
				margin-bottom: 8px;
				cursor: pointer;
				padding: 4px 16px;

				.menu-title {
					white-space: nowrap;
				}
			}

			& .divider { display: none; }
		}
	}

	.edit-section {
		margin: auto;
		width: 50%;
	}
}

/* STYLE SCREEN IS <768px */
@media (max-width: 767px) {
	.account-settings-container {

		.settings-side-menu {
			margin: auto 8px auto 0 !important;
			height: fit-content;
			padding: 8px !important;
			border-radius: 0 10px 10px 0;

			.sidemenu-header {
				display: none;
			}

			.settings-menu-item {
				
				width: 20px;
				height: 40px;
				justify-content: center;

				.menu-title {
				  	display: none;
				}
			}

			& .divider { display: block; }
			& hr { display: none; }

			
		}

		.edit-section {
			width: 70%;
		}
	}
}
