.no-access {
    color: red;
    font-size: 36px;
    text-align: center;
    margin-top: 240px;
}

.admin {
    background-image: url('../assets/sign_bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
  
.admin-container {
    /* Uncomment the background if needed */
    /* background: rgba(255, 255, 255, 0.95); */
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-height: 80vh;
    padding: 24px;
    width: 80%;
    max-width: 1200px;
    margin: 0 auto;
}
  
.admin-container .admin-title {
    margin-top: 20px;
    margin-left: 20px;
    font-size: var(--text-lg);
    color: var(--acc-blue);
}
  
.admin-container .auth-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
  
.admin-container .auth-container .auth-h2 {
    /* Additional styling for auth headings, if needed */
}
  
.admin-container .auth-container .input-email-section,
.admin-container .auth-container .file-upload-section {
    border-radius: 10px;
    background-color: var(--black-10);
    padding: 16px;
    width: 50%;
    margin-right: 16px;
}
  
.admin-container .auth-container .input-email-section .form,
.admin-container .auth-container .file-upload-section .form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #000;
}
  
.admin-container .auth-container .input-email-section .form input {
    margin-top: 12px;
    width: 75%;
    color: #000;
}
  
.admin-container .auth-container .input-email-section .form .button {
    width: 150px;
}
  
/* New Notifications Section */
.notifications-section {
    background-color: var(--black-10);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 24px;
    width: 80%;
    max-width: 1200px;
    margin: 20px auto 0 auto;
}
  
.notifications-section h1 {
    margin: 0 0 20px;
    text-align: center;
    font-size: var(--text-lg);
    color: var(--acc-blue);
}
  
.notifications-section form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
  
.notifications-section form label {
    font-size: var(--text-sm);
    font-weight: bold;
    color: var(--text-grey);
    display: flex;
    flex-direction: column;
    gap: 5px;
}
  
.notifications-section form input[type="text"],
.notifications-section form input[type="url"],
.notifications-section form input[type="datetime-local"],
.notifications-section form textarea {
    width: 100%;
    padding: 10px;
    font-size: var(--text-base);
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #000;
}
  
.notifications-section form textarea {
    resize: vertical;
    min-height: 80px;
    color: #000; 
}
  
.notifications-section form button {
    background-color: var(--acc-blue);
    color: #fff;
    border: none;
    padding: 12px;
    width: 100%;
    font-size: var(--text-base);
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}
  
.notifications-section form button:hover {
    background-color: #365bb7;
}
  
.notifications-section .preview {
    margin-top: 10px;
    text-align: center;
    display: none;
}
  
.notifications-section .preview img {
    max-width: 100%;
    border-radius: 4px;
}
  
.notifications-section #status {
    margin-top: 15px;
    text-align: center;
    font-weight: bold;
    color: var(--text-grey);
}

/* Responsive adjustments */
@media (max-width: 913px) {
    .admin {
        padding: 10px;
    }
    
    .admin-container,
    .notifications-section {
        width: 90%;
        padding: 16px;
    }
    
    .admin-container .auth-container {
        flex-direction: column;
        gap: 16px;
    }
    
    .admin-container .auth-container .input-email-section,
    .admin-container .auth-container .file-upload-section {
        width: 100%;
    }
}