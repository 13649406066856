.shimmerpost {
    display: flex;
    gap: 8px;
    margin: 16px 0;
	height: fit-content;


    & .circle {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
        background-size: 300%;
        background-position-x: 100%;
        animation: shimmer 1s infinite linear;
        border-radius: 99px;
    }

    & .shimmer {
        width: 100%;
        background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
        background-size: 300%;
        background-position-x: 100%;
        animation: shimmer 1s infinite linear;
        border-radius: 10px;
    }
}

@keyframes shimmer {
    to {
       background-position-x: 0%
    }
 }