.featured-section-component {
	& .featured-section-container {

		.featured-tabs {
			display: flex;
			justify-content: space-around;

			& .featured-tab-item {
				font-size: var(--text-md);
				font-weight: bold;

				& .tab-underline {
					width: 100%;
					height: 2px;
					border-radius: 1px;
				}
			}
		}

		& .media-music {
			padding: 24px 0;
			display: flex;
			gap: 16px;
			flex-wrap: wrap;

			.upload-track {
				padding: 12px;
				display: flex;
				border: 1px dashed var(--acc-blue);
				border-radius: 14px;
				background-color: var(--black-10);
				justify-content: center;
				gap: 16px;

				.icon {
					margin: auto 0;
					color: var(--acc-blue);
				}

				& p { 
					margin: auto 0; 
					color: var(--acc-blue);
					font-size: var(--text-md);
				}
			}

			.upload-track:hover {
				cursor: pointer;
			}

			.track-list {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
				gap: 16px;
				max-width: 100%;
			}

			.track-item {
				background-color: var(--black-10);
				padding: 12px;
				border-radius: 14px;
				display: flex;
				flex-direction: column;
				gap: 8px;

				&:hover {
					background-color: var(--black-20);
				}
				
				.track-thumbnail {
					width: 50px;
					height: 50px;
					min-width: 50px;
					min-height: 50px;
					cursor: pointer;

					& img {
						width: 100%;
						height: 100%;
						border-radius: 8px;
					}
				}

				.track-details {
					margin: auto 0;
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 4px;
					& p { margin: 0; }

					.track-title {
						font-weight: 600;
						font-size: var(--text-base);
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.track-artists {
						font-size: var(--text-md);
					}

					.track-album-year {
						font-size: var(--text-sm);
						color: var(--text-grey);
					}
				}

				.play-button {
					margin: auto 0;
				}
			}

			.track-item .slider {
				width: 100%;
				margin-top: 8px;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
			}

			&.playing {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		& .merch {
			padding: 24px 0 48px;
			gap: 16px;
			flex-wrap: wrap;

			& .mixes-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding: 4px;

				.upload-merch {
					border-radius: 14px;
					width: 180px;
					border: 1px solid var(--acc-blue);
					border-radius: 99px;
					background-color: var(--black-10);
					justify-content: center;
					gap: 8px;
					text-align: center;
					display: flex;
					flex-direction: row;
					align-items: center;
					height: 40px;
					width: 170px;

					cursor: pointer;
	
					.icon {
						color: var(--acc-blue);
					}

					& p { 
						color: var(--acc-blue);
						font-size: var(--text-md);
					}
					
				}
			}

			& .merch-list {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				gap: 16px;
				.merch-item {
					background-color: var(--black-10);
					padding: 12px;
					border-radius: 14px;
					width: 180px;
	
					display: flex;
					flex-direction: column;
					align-items: center;
					min-height: 270px;
					min-width: 270px;
	
					.merch-thumbnail {
						width: 180px;
						height: 180px;
	
						& img {
							width: 100%;
							height: 100%;
							border-radius: 8px;
						}
					}
	
					.merch-details {
						margin: auto 0;
						padding: 16px 0;
						& h3 { margin: 0; }
	
						.merch-title {
							font-weight: 600;
							font-size: var(--text-md);
						}
	
						.merch-description {
							font-size: var(--text-sm);
							color: var(--text-grey);
							overflow: hidden;
							display: -webkit-box;
							-webkit-line-clamp: 2; /* number of lines to show */
									line-clamp: 2; 
							-webkit-box-orient: vertical;
						}
	
						.merch-price {
							font-weight: bold;
							padding: 4px 0;
						}
					}
	
					.buy-button {
						display: flex;
						border: 1px solid var(--main-blue);
						border-radius: 99px;
						justify-content: center;
						padding: 8px;
	
						& .icon { margin: auto 0; }
						& p { 
							margin: 0 12px; 
							font-size: var(--text-md);
							color: var(--main-blue);
							font-weight: bold;
						}
	
					}
				}
			}
		}

		& .events {

			padding: 24px 0;
			gap: 16px;

			.create-event {
				padding: 12px;
				border-radius: 14px;
				border: 1px dashed var(--acc-blue);
				border-radius: 14px;
				background-color: var(--black-10);
				justify-content: center;
				text-align: center;

				.icon-text {
					display: flex;
					margin: auto;
					justify-content: center;

					& .icon {
						margin: auto 12px;
					}

					& p { 
						color: var(--acc-blue);
						font-size: var(--text-md);
					}
				}
			}

			.event-item {
				background-color: var(--deep-blue-10);
				padding: 12px;
				border-radius: 10px;
				margin-top: 16px;
				display: flex;
				gap: 24px;
				cursor: pointer;
				box-shadow: -8px 8px 0 2px rgba(0,0,0,0.5);
				/* border: 1px solid var(--sand-beige-20); */

				.event-thumbnail {
					min-width: 250px;
					max-width: 250px;
					max-height: 250px;
					min-height: 250px;

					& img {
						width: 100%;
						height: 100%;
						border-radius: 8px;
					}
				}

				.event-details {
					.event-title {  
						margin: 0;
					}
					.event-date-loc {
						font-size: var(--text-md);
						margin: 8px 0 0;
						display: flex;
						gap: 8px;
					}

					.event-date-posted {
						font-size: var(--text-sm);
						color: var(--text-grey);
						margin: 16px 0 8px;
					}
					& .event-description {
						font-size: var(--text-md);
						color: var(--stone-grey);
						white-space: pre-wrap !important;  /* Ensures whitespace and line breaks are preserved */
    					overflow-wrap: break-word !important; /* Breaks long words within the container */
						display: -webkit-box; /* Enables the flexible box layout for truncation */
						-webkit-box-orient: vertical; /* Specifies the box's orientation */
						overflow: hidden; /* Hides the overflowing text */
						-webkit-line-clamp: 3; /* Limits the paragraph to 3 lines */
						text-overflow: ellipsis; /* Adds ellipsis (...) to truncated text */
					}

					.performers-list {
						display: flex;
						flex-direction: row;
						align-items: center;
						gap: 8px;
	
						& p { margin: 12px 0 0; }
						& .performers-list-label {
							font-size: var(--text-md);
						}
						& .performer {
							font-weight: 700;
							color: var(--sand-beige);
						}
					}

					.invite-response {
						display: flex;
						gap: 8px;
						margin: 8px 0;
						.response-option {
							display: flex;
							flex-direction: row;
							align-items: center;
							padding: 8px 12px;
							gap: 8px;
							border: 1px solid var(--sand-beige);
							border-radius: 8px;

							& p {
								margin: 0;
								color: var(--sand-beige);
								font-size: var(--text-md);
							}
						}

						& .response-option:hover {
							background-color: var(--cyan-10);
						}
					}
				}
			}
		}
	}
}

/* When a track is playing, switch the layout of the track item to column so the slider is placed below the main content */
.featured-section-container .media-music .track-list .track-item.playing {
    flex-direction: column;
    align-items: flex-start;
}

/* Adjust the slider in the playing state */
.featured-section-container .media-music .track-list .track-item .slider {
    width: 100%;
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

/* The progress-bar styles can remain the same */
.featured-section-container .media-music .track-list .track-item .slider .progress-bar {
    width: 100%;
    height: 3px;
    appearance: none;
    border-radius: 2px;
}

.featured-section-container .media-music .track-list .track-item .slider .progress-bar::-webkit-progress-bar {
    background-color: var(--text-grey);
    border-radius: 5px;
}

.featured-section-container .media-music .track-list .track-item .slider .progress-bar::-webkit-progress-value {
    background-color: var(--cyan);
    border-radius: 5px;
}

.featured-section-container .media-music .track-list .track-item .slider .progress-bar::-moz-progress-bar {
    background-color: var(--cyan);
}