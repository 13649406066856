.notifications-page {
	height: 96vh;

	& .container {

		padding: 48px 16px;
		margin: auto;

		& .notification-row {

			background-color: var(--acc-blue-50);
			border-radius: 10px;
			padding: 16px;
			display: flex;
			gap: 16px;
			justify-content: space-between;

			& .left-aligned-content {
				display: flex;
				gap: 16px;

				& .notif-thumbnail {
					height: 50px;
					width: 50px;
					border-radius: 99px;
					background-color: var(--acc-blue);
				}

				& .notif-content {
					p { margin: 0 0 8px; }

					.notif-title {
						font-size: var(--text-base);
						font-weight: bold;
					}
					.notif-body {
						font-size: var(--text-md);
					}
				}
			}

			& .right-aligned-content {
				& .new-notif-indicator {
					height: 10px;
					width: 10px;
					border-radius: 10px;
					background-color: var(--acc-blue);
					margin: 8px;
				}
			}
		}
	}
}

/* STYLE SCREEN IS >820px */
@media (min-width: 821px) {

	.notifications-page {
		& .container {
			max-width: 700px;
		}
	}
	
}

/* STYLE SCREEN IS <820px */
@media (max-width: 820px) {

	.notifications-page {
		.container {
			max-width: 100%;
		}
	}
	
}