/* General container styling */
.featured-events-component {
    text-align: center;
    padding: 24px;
  }
  
  /* Slider container */
  .slider-container {
    margin: 0 auto;
    position: relative;
  }
  
  /* Carousel container */
  .carousel-container {
    max-width: 500px;
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(0.8); /* Non-active slides are smaller */
    opacity: 0.5; /* Non-active slides are dimmed */
  }
  
  /* Active slide (centered) */
  .slick-center .carousel-container {
    transform: scale(1); /* Active slide is larger */
    opacity: 1; /* Active slide is fully visible */
    z-index: 2; /* Bring active slide to the front */
  }
  
  /* Poster styling */
  .poster {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 500px;
  }
  
  .poster img {
    width: 100%;
    border-radius: 10px;
    z-index: 1;
  }
  
  /* Overlay for non-active slides */
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Light overlay */
    border-radius: 10px;
    z-index: 2; /* Overlay appears above image */
  }
  
  .slick-center .overlay {
    display: none; /* No overlay for the active slide */
  }
  
  /* Arrows */
  .slick-prev, .slick-next {
    z-index: 3;
    /* color: #000; */
  }
  
  .slick-prev:hover, .slick-next:hover {
    color: #555; /* Arrow hover effect */
  }
  
  /* Dots */
  .slick-dots li button:before {
    color: #ccc; /* Default dot color */
  }
  
  .slick-dots li.slick-active button:before {
    color: #000; /* Active dot color */
  }