.discover-people-slider {
	height: fit-content;
	.container {
		.user-cards {
			overflow-x: scroll;
			display: flex;
			gap: 16px;
		}
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
* {
  scrollbar-width: none; /* or 'thin' for minimal width */
}