.messages {
	height: 96vh;
	
	.messages-container {
		position: relative;

		.messages-main {
			margin-top: 60px;
			display: flex;
			position: fixed;
			right: 0;
			left: 0;
			bottom: 0;
			top: 0;

			.messages-list {
				background-color: var(--black-10);
				width: 30%;

				.messages-list-header {
					display: flex;
					gap: 16px;
					justify-content: space-between;
					padding: 16px;
					background-color: var(--black-10);

					& .search-bar {
						border: 2px solid var(--acc-blue-50);
						border-radius: 20px;
						padding: 8px 16px;
					}

					.write-icon {
						cursor: pointer;
						margin: auto 0;
					}
				}

				.message-item {
					padding: 16px;
					display: flex;
					justify-content: space-between;
					cursor: pointer;

					.image-and-content {
						display: flex;
						gap: 16px;
						flex-direction: row;
						align-items: center;
						.message-content {
							p {
								margin: 0;
							}

							.recipient-name {
								font-size: var(--text-base);
								font-weight: bold;
							}
							.message-preview {
								font-size: var(--text-md);
								padding: 4px 0;
								color: var(--text-grey);
							}
						}
					}

					

					.time-and-indicator {
						align-items: end;

						.time-ago {
							margin: 0; 
							padding: 0;
							font-size: var(--text-sm);
							color: var(--text-grey);
						}

						.indicator {
							margin: 8px auto;

						}
					}
				}
			}

			.selected-conversation {
				border-radius: 0 10px 0 0;
				width: 100%;
/*				border-left: 1px solid var(--faded-white);*/

				.conversation-header {
					display: flex;
					justify-content: space-between;
					padding: 16px;
					background-color: var(--black-10);
					height: 38px;
					position: relative;

					.conversation-title { 
						margin: auto 0; 
						padding: 0;
						font-weight: bold;
					}

					.more-icon {
						margin: auto 0;
						cursor: pointer;

					}

					.chat-options-modal {
						position: absolute;
						top: 48px;
						right: 16px;
						padding: 0 16px;
						width: fit-content;
						z-index: 99px;

						background-color: var(--dark-blue);
						border-radius: 10px;

						.chat-options-list {
							list-style: none;

							& li {
								margin: 0 0 0 -32px;
							}

						}
					}
				}

				.conversation {
					padding: 16px;
					position: relative;
					height: 80vh;
					border-left: 1px solid var(--black-10);

					.bubbles {
						position: absolute;
						bottom: 0;
						right: 0;
						left: 0;

						.message-row {
							margin: 8px 16px;
							display: flex;
							gap: 16px;
						}

						.message-day {
							margin: 16px auto;
							background-color: var(--dark-blue-50);
							padding: 8px 12px;
							border-radius: 20px;
							width: fit-content;
							.day-label {
								font-size: var(--text-sm);
								text-align: center;
								margin: 0;
							}
						}
						
						.incoming-message-bubble {
							padding: 10px 12px;
							border-radius: 10px;
							width: fit-content;
							max-width: 300px;
							background-color: var(--ocean-blue);

							& p {
								margin: 0;
							}
						}

						.outgoing-message {
							justify-content: end;
						}

						.outgoing-message-bubble {
							padding: 10px 12px;
							border-radius: 10px;
							width: fit-content;
							max-width: 300px;
							background-color: var(--dark-blue);
							margin: auto 0;

							& p {
								margin: 0;
							}
						}

						.message-body .mention-link { 
							color: var(--cyan); /* Example color */
							text-decoration: none;
							font-weight: bold;
						}
							  
						.message-body .mention-link:hover {
							text-decoration: underline;
						}
						 

						.time {
							font-size: var(--text-xs);
							color: var(--faded-white);
						}

						.compose-message-section { 
							padding: 16px;
							margin: 16px;
							border-radius: 10px;
							background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,46,81,0) 100%);
							.compose-message-content {
								display: flex;
								gap: 16px;

								.textarea {
									border-radius: 12px;
									width: 100%;
									.compose-message {
										font-size: var(--text-base);
										padding: 24px 12px 0;
										height: calc(1.2em * 1);
										line-height: 1.2em;
									}
								}

								.send-icon {
									margin: auto;
									display: flex;
									background-color: var(--deep-blue);
									border-radius: 99px;
									padding: 8px 16px;
									width: 80px;
									justify-content: space-around;
									cursor: pointer;

									& .btn-icon {
										margin: auto 0;
									}

									& .btn-label {
										color: var(--cyan);
										font-weight: 500;
										margin: 0;
									}
								}

								.vertical-divider {
									height: 24px;
									width: 1px;
									background-color: var(--dark-blue);
									margin: auto 0;
								}

								.attachments {
									margin: auto 0;
									display: flex;
									gap: 24px;
									.attachment-icon {
										cursor: pointer;
									}
								}
							}
						}

					}

					
				}

				.empty-conversation {
					margin: auto;
					text-align: center;
					height: 100%;

					.empty-message {
						margin-top: 20%;
						font-weight: bold;
						font-size: var(--text-md);
						color: var(--faded-white);
					}
				}
			}
		}
	}
}