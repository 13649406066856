.user-profile-follows {

	& .follows-tabs { 
		display: flex; 
		justify-content: center;
		gap: 16px;
	}

	.tab-item {
		width: fit-content;
		text-align: center;

		& .count {
			padding: 8px;
		}

		& .tab-underline {
			width: 100%;
			height: 2px;
			border-radius: 1px;
		}

	}

	& span {
		font-size: var(--text-base);
		margin: 0 4px;

	}

	& .count-number { font-weight: bold; }

	.user-follows-card {
		display: flex;
		justify-content: space-between;
		max-width: 500px;
		margin: 16px auto;

		.image-name {
			display: flex;
			gap: 16px;
		}

		.user-image-card {
			min-width: 35px;
			width: 35px;
			max-height: 35px;
			border-radius: 100%;
			background-color: rgba(255,255,255,0.2);
			margin: auto 0;

			img {
		        width: 100%;
		        height: 100%;
		        border-radius: 100%;

		      }
		}

		.user-name-card {
			font-size: var(--text-md);
			font-weight: bold;
			margin-bottom: -6px;
		}

		.user-handle-card {
			font-size: var(--text-sm);
			color: var(--text-grey);
		}

		& .button {
			margin: auto 0;
		}
	}
}