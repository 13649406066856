.discover-people-panel {
	padding: 0 24px 24px;
	height: fit-content;

	& .cards-container {
		height: auto;

		.user-cards {
			display: flex;
			flex-wrap: wrap;
			gap: 12px;
			overflow-y: scroll;
		}
	}
}