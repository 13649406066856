.pages-menu {
	margin: 36px 0;
	& .menu-list {

		& .link-text {
			text-decoration: none;
		}
		& .enabled { 
			cursor: pointer; 

			&:hover {
				background-color: var(--deep-blue-50);
			}
		}
		& .disabled { cursor: not-allowed; }
		& .menu-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 18px;
			padding: 16px 24px;
			border-radius: 10px;
			background-color: var(--deep-blue-20);
			margin-bottom: 8px;

			& .menu-icon {
				width: 24px;
			}
			& p {
				font-weight: bold;
				font-size: var(--text-md);
				margin: 0;
			}
		}

		& .menu-logout {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 12px;
			padding: 16px 24px;
			border-radius: 10px;
			margin-bottom: 8px;
			cursor: pointer;


			& .menu-icon {
/*				width: 16px;*/
			}
			& p {
				font-weight: bold;
				font-size: var(--text-sm);
				margin: 0;
			}
		}
	}

}

/*STYLE SCREEN IS <853px */
@media (max-width: 853px) {

	.pages-menu {
		& .menu-list {
			display: flex;
			flex-direction: column;
			& .heading {
				display: none;
			}

			& .menu-item, .menu-logout {
				width: fit-content;
				padding: 12px 16px;

				& .menu-icon {
/*					width: 18px;*/
				}
				& p, span {
					display: none;
				}
			}
		}

	}
	
}