.mainscreen {
	height: 89vh;
  	padding: 100px 16px 0;
  	overflow-y: hidden;

  	span { font-size: var(--text-sm); }

	.mainscreen-content { 
		display: flex;
		justify-content: space-between;
		width: 100%;
		gap: 16px;

		

		.profile-pane {
			width: 20%;

			.min-menu {
				display: none;
			}

			& hr { display:none; }
		}

		.feed { 
			width: 50%;
			overflow-y: scroll;
			height: 94vh;
			margin-bottom: 32px;

			& .create-post-section {
			}

			& .newsfeed-tabs { 
				display: flex; 
				justify-content: space-around;
				gap: 16px;
				margin-bottom: 8px;


				.tab-item {
					width: fit-content;
					text-align: center;

					& .label {
						padding: 8px;
					}

					& .tab-underline {
						width: 100%;
						height: 2px;
						border-radius: 1px;
					}

					& span {
						font-size: var(--text-md);
						font-weight: bold;
						margin: 0 4px;

					}	

				}
			}

			& .active-bubble {
				display: flex;
				height: fit-content;
				justify-content: space-between;
				padding: 12px;
				/* background: linear-gradient(180deg, rgba(0,0,0,0.1) 0%, rgba(0,46,81,0) 100%); */
				background: transparent;
				border-radius: 8px 8px 0 0;
				border-top: 1px solid #fff;
				animation: fadeSlideDown 0.3s ease-out forwards; /* Apply the animation */

				/* Auto layout */
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 12px;
				gap: 8px;

				border-top: 2px solid #B58B4A;
				/* filter: drop-shadow(0px 0px 1px #F4E3C1); */

				& .left-texts {
					display: flex;
					gap: 8px;

					& .text {
						margin: auto 0;
						color: var(--text-grey);
						font-size: var(--text-md);
					}
				}
				& .right-texts {
					margin: auto 0;
				}
			}
		}

		.discover-people-slider {
		  display: none;
		}

		.web-right-panel { 
			width: 25%;
		}
	}

}

/*STYLE SCREEN IS <853px */
@media (max-width: 853px) {		

	.mainscreen {
		& .mainscreen-content {
			.profile-pane {
				padding: 0 24px 0 0;
				width: fit-content;

				.profilepanel {
					display: none !important;
				}

				.min-menu {
			    	display: block;
			    }

			    & hr { display: block; }
		    }

		    

		    .web-right-panel {
		    }

		    .feed {
		    	width: 100%;
			}
			.discover-people-slider {
		    }
		}
	}
	
}

/*STYLE SCREEN IS <820px */
@media (max-width: 820px) {

	.mainscreen {
		& .mainscreen-content {
			.profile-pane {

		        .profilepanel {
				}
		    }

		    .web-right-panel {
		        display: none !important;
		    }

		    .feed {
				
			}
			.discover-people-slider {
		      display: block;
		    }
		}
	}
	
}
