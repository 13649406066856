.admin-sidemenu {
	width: 300px;
	background-color: var(--black-10);

	.sidemenu-container {
		padding: 24px;

		.menu-list {
			.list {
				list-style: none;
				padding: 0;

				.menu-item {
					padding: 16px;
					border-radius: 8px;
					cursor: pointer;
				}
			}
		}
	}
}