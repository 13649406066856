.forgotpassword-page {
	background-image: url('../assets/sign_bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100vh;

	& .container {
		height: 100vh;
		padding-top: 10%;

		& .upper-section {
			height: fit-content;

			& .logo {
				text-align: center;
				& .submerge-logo {
					width: 500px;
					margin: auto 0;
				}

				& .tagline {
					margin: auto;
					text-align: center;
				}
			}
		}

		& .forgot-password-card {
			margin: 48px auto;
			padding: 24px;
			background-color: var(--black-10);
			border-radius: 20px;
			width: 400px;

			& .card-body { font-size:var(--text-md); }
		}
	}
}

@media (max-width: 820px) {
	.forgotpassword-page {

	& .container {
		padding-top: 20%;
		& .upper-section {

			& .logo {
				& .submerge-logo {
					width: 300px;
					margin: auto 0;
				}

				& .tagline {
					font-size: var(--text-md);
				}
			}
		}

		& .forgot-password-card {
			width: 70%;

			& .card-body { }
		}
	}
}
}