.profile-page{
	height: 100%;
  	padding: 70px 16px 20px;
}

/* STYLE SCREEN IS <820px */
@media (max-width: 820px) {
	.profile-page-content {
		& .active-bubble {
			display: flex;
			height: fit-content;
			justify-content: space-between;
			padding: 12px;
			background: transparent;
			border-radius: 8px 8px 0 0;
			border-top: 2px solid #B58B4A;
			animation: fadeSlideDown 0.3s ease-out forwards;
	  
			flex-direction: row;
			align-items: center;
			gap: 8px;
	  
			& .left-texts {
			  display: flex;
			  gap: 8px;
	  
			  & .text {
				margin: auto 0;
				color: var(--text-grey);
				font-size: var(--text-md);
			  }
			}
	  
			& .right-texts {
			  margin: auto 0;
			}
		  }

		& .profile-mobile-head {
			background-color: var(--deep-blue-20);
			height: fit-content;
			padding: 42px 0 16px;
			margin-left: -5%;
			margin-right: -5%;

			border-radius: 0 0 100% 100%;

			.profile-mobile-userphoto {
				margin: 0 auto 24px;
				width: fit-content;
				-webkit-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				-moz-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				border-radius: 100%;

				.header-avatar {
					width: 120px;
					height: 120px;
					border-radius: 100%;
					border: 0.2px solid var(--main-blue);
				}
			}

			.profile-mobile-navigation {
				display: flex;
				padding: 24px 0 0;
				justify-content: space-evenly;
			}


			.posts-icon {
	/*			position: absolute;*/
				margin-top: -25px;
			}

			.people-icon {
	/*			position: absolute;*/
				margin-top: -25px;
			}

			.profile-nav-indicator {
				width: 5px;
				height: 5px;
				margin: 8px auto 0;
				border-radius: 100%;
			}


		}

		.user-posts {
			width: 100%;
			margin-top: 24px;
		}

		.user-profile-info {
			margin: 24px 0;
			text-align: center;
			padding: 24px;
			background-color: var(--deep-blue-10);

			& p { margin-top: -4px; font-size: var(--text-md); }

			.user-profile-details {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: center;
				gap: 8px;margin-top: 24px;

				& p {
					margin: 0;
					color: var(--text-grey);
					font-size: var(--text-base);
				}
			}

			.edit-profile-button {
				margin: 0 auto;
				padding: 8px 0;
				width: 150px;

			}

			.cta {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				gap: 16px;
				width: fit-content;
				margin: 0 auto;

			}

			.user-info-cta {
				margin: 0 auto;
				width: 100px;
			}
		}

		.user-profile-follows {
			margin-top: 24px;
		}

		.discover-panel {
			display: none;
		}

	}
}

/* STYLE SCREEN IS >820px */
@media (min-width: 821px) {
	.profile-page-content {
		height: 90vh;
		margin-top: 20px;
		justify-content: space-between;
		display: flex;
		gap: 32px;

		& .active-bubble {
			display: flex;
			height: fit-content;
			justify-content: space-between;
			padding: 12px;
			background: transparent;
			border-radius: 8px 8px 0 0;
			border-top: 2px solid #B58B4A;
			animation: fadeSlideDown 0.3s ease-out forwards;
	  
			flex-direction: row;
			align-items: center;
			gap: 8px;
	  
			& .left-texts {
			  display: flex;
			  gap: 8px;
	  
			  & .text {
				margin: auto 0;
				color: var(--text-grey);
				font-size: var(--text-md);
			  }
			}
	  
			& .right-texts {
			  margin: auto 0;
			}
		}

		& .profile-mobile-head {
			background-color: var(--deep-blue-20);
			height: 55vh;
			padding: 0 0 0 16px;
			margin: auto auto auto -1%;
			width: 350px;
			display: flex;

			border-radius: 0 300px 300px 0;

			.profile-mobile-userphoto {
				margin: auto;
				width: 150px;
				height: 150px;
				-webkit-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				-moz-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
				border-radius: 100%;

				.header-avatar {
					width: 150px;
					height: 150px;
					border-radius: 100%;
					border: 0.2px solid var(--main-blue);
				}
			}

			.profile-mobile-navigation {
				display: block;
				margin: auto;

				& .nav-icon {
					padding: 32px 16px;
					display: flex;
					& img {
						margin: auto;
					}
				}
			}

			.info-icon {
				display: flex;
				gap: 0;
			}

			.profile-nav-indicator {
				width: 5px;
				height: 5px;
				margin: 8px 4px 0;
				border-radius: 100%;
			}


		}

		.user-posts {
			width: 80%;
		}

		.profile-main-content {
			width: 80%;

			.user-profile-info {
				text-align: center;
				padding: 24px;
				margin: 24px auto;
				border: 1px solid var(--sand-beige-10);
				background-color: var(--deep-blue-10);
				border-radius: 20px;

				& p { margin-top: -4px; font-size: var(--text-md); }

				.name-and-cta {
					justify-content: space-between;

					.names { 
						align-items: start;
						margin: 0 auto;

						.userhandle { 
							padding: 0; 
							color: var(--text-grey);
							font-size: var(--text-base); 
						}
					}
					.cta {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						gap: 16px;
						width: fit-content;
						margin: 0 auto;

						.edit-profile-button {
							margin: 0;
							width: fit-content;
						}
						.follow-unfollow-button {
							margin: 0;
							width: fit-content;
						}
					}

				}

				.user-profile-details {
					display: flex;
					flex-direction: row;
					align-items: flex-start;
					justify-content: center;
					gap: 8px;

					& p {
						margin: 0;
						color: var(--text-grey);
						font-size: var(--text-base);
					}
				}
				
			}

			.profile-featured-section {
				margin: 48px auto 0;
			}
		}

		

		.user-profile-follows {
			width: 80%;
		}

		.discover-panel {
			width: 40%;
		}

	}
}