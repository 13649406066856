.create-post {
	font-size: var(--text-base);
	padding: 0;
	animation: fadeSlide 0.1s ease-out forwards; /* Apply the animation */

	& .container {
/*		background: rgba(0, 46, 81, 0.3);*/
		background: var(--deep-blue-20);
		padding: 16px;
		border-radius: 10px;
		box-shadow: 10px 0 20px -9px rgba(0,0,0,0.1);
		margin-bottom: 16px;
		border: 1px solid var(--cyan-50);
		.image-text {
			display: flex;
			gap: 16px;

			.content {
				width: 100%;
				margin-top: -10px;

				.post-compose {
					font-family: 'Noto Sans', sans-serif;
				}

				.popup-trigger {
/*					width: 100%;*/
				}
			}
		}

		.post-actions {
			border-top: 1px solid var(--cyan-10);
			padding-top: 16px;
			justify-content: space-between;
			width: 100%;
			display: flex;

			.add-content {
				gap: 16px;
				display: flex;
				flex-direction: row;
				align-items: center;
				/* visibility: hidden; */

				& .icon-btn {
					cursor: pointer;
				}
			}

			& .btn {
				width: 15%;
			}
		}


	}
}
