.newsfeed-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .newsfeed-card {
    font-size: var(--text-base);
  }
  
  .newsfeed-card p {
    width: fit-content;
    margin: 0;
    padding-bottom: 12px;
  }
  
  .newsfeed-card-container {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
    align-items: flex-start;
  }
  
  /* Profile Picture Container */
  .user-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;
  }
  
  .user-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Post Content */
  .post-content {
    
  }
  
  .post-content .mention-link {
    color: var(--acc-blue);
    text-decoration: none;
    font-weight: bold;
  }
  
  .post-content .mention-link:hover {
    text-decoration: underline;
  }
  
  /* Post Actions */
  .post-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin: 20px 0 0;
  }
  
  .post-actions .post-data-count {
    margin: auto 0;
  }
  
  .post-actions .post-data-count span {
    color: var(--text-grey);
  }
  
  .post-actions .pill {
    display: flex;
    gap: 8px;
  }
  
  .post-actions .like-comment {
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: flex-end; /* Ensures icons stay right-aligned */
    gap: 12px;
    padding: 8px 12px;
    border-radius: 20px 0 10px 0;
}
  
  /* Comment Input */
  .comment-popup {
    padding-top: 16px;
    display: flex;
    gap: 16px;
    margin-bottom: 42px;
  }
  
  .comment-popup textarea {
    border-radius: 99px;
    padding: 12px 16px 0;
    background-color: var(--deep-blue-20);
  }
  
  /* Post Edit Mode */
  .edit-post-actions {
    display: flex;
    gap: 12px;
  }
  
  .edit-post-actions button {
    background-color: transparent;
    border: none;
    border-radius: 20px;
    padding: 6px 12px;
    font-size: var(--text-md);
    font-weight: bold;
    color: var(--ocean-blue);
    cursor: pointer;
  }
  
  .edit-post-actions button:hover {
    text-decoration: underline;
  }
  
  /* Time, Options, and Modals */
  .no-result {
    text-align: center;
    margin: 0 auto;
    color: var(--text-grey);
    font-size: var(--text-md);
  }
  
  .time-ago {
    position: absolute;
    right: 38px;
    top: 12px;
    color: #fff;
    opacity: 50%;
  }
  
  .post-more-icon {
    position: absolute;
    right: 12px;
    top: 12px;
    color: #fff;
    cursor: pointer;
  }
  
  .post-options-modal {
    position: absolute;
    right: 12px;
    top: 38px;
    color: #fff;
    width: fit-content;
    z-index: 99;
    background-color: var(--dark-blue);
    border-radius: 10px;
  }
  
  .post-options-modal .post-options-list {
    list-style: none;
  }
  
  .post-options-modal .post-options-list li {
    margin: 0 8px 0 -32px;
    cursor: pointer;
    padding: 8px 8px;
    border-radius: 4px;
  }
  
  .post-options-modal .post-options-list li .option-name {
    font-size: var(--text-base);
    margin-left: 12px;
  }
  
  .post-options-modal .post-options-list li:hover {
    background-color: var(--black-10);
  }