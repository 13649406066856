.event-post-component {
    padding: 12px;
    background-color: var(--black-20);
    border-radius: 12px;
    margin: 4px 0 12px;
    width: 100%;
    & .event-content {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 16px;
        & .event-image {
                width: auto;
                height: 500px;
                object-fit: cover;
            & img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        & .event-info {
            width: 100%;
            & .event-deets {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 32px;
                & .event-body {
                    & .event-title {
                        font-weight: bold;
                        font-size: var(--text-base);
                    }
                    & .event-description {
                        font-size: var(--text-md);
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* Limits text to 2 lines */
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis; /* Ensures ellipsis */
                        max-width: 100%; /* Adjust as needed */
                        height: 20px;
                    }
                }

                & .cta-btn {
                    width: 55%;
                }
            }
        }
    }
}