.create-post-overlay {
	z-index: 99;
	position: absolute;
	background: rgba(0, 0, 0, .5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 60px;

	& .overlay-content-web {
		font-size: var(--text-base) ;
/*		background: var(--dark-blue);*/
  		background-image: url('../assets/desktopbg.png');
		padding: 8px 16px;
		border-radius: 12px;
		width: 600px;
		margin: auto;


		textarea { 
			padding: 0; 
			font-size: var(--text-md);
		}

		.container-header {
			display: flex;
			justify-content: space-between;
			font-weight: bold;
		}
		.post-actions {
			justify-content: space-between;
			width: 100%;
			display: flex;
			padding: 8px 0;

			.add-content {
				gap: 8px;
				display: flex;
			}
		}
	}
	

	& .overlay-content-mobile {
/*		background: var(--dark-blue); */
  		background-image: url('../assets/desktopbg.png');
  		background-position: bottom;
		padding: 16px;
		border-radius: 12px 12px 0 0;
		height: 100%;

		.container-header {
			display: flex;
			justify-content: space-between;
			font-weight: bold;
		}

		.compose-section {
  			background-image: url('../assets/desktopbg.png');
  			background-position: right;
			padding: 16px;
			margin: 16px auto;
			border-radius: 8px;
			background-opacity:;
		}
		.compose-area {
			display: flex;
		}

		textarea { 
			padding: 0 16px;
			font-size: var(--text-md);
		}
		.post-actions {
			justify-content: space-between;
			width: 100%;
			display: flex;

			.add-content {
				gap: 8px;
				display: flex;
			}
		}
	}
	
}


/* STYLE SCREEN IS <768px */
@media (max-width: 768px) {
	.create-post-overlay {
		padding: 40px 0 0;

		.overlay-content-web { 
			display: none;
		}
	}
	
}

/* STYLE SCREEN IS >768px */
@media (min-width: 769px) {

	.create-post-overlay {
		.overlay-content-mobile {
			display: none;
		}
	}
	
}




