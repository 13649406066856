.contact-us-page {
    .lpbg {
        position: fixed;
        width: 100%;
        z-index: -98;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .contact-us-main {
        margin: auto;
        width: 33%;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60px;

        & .submerge-logo {
            width: 200px;
            & img {
                width: 100%;
            }
        }

        .contact-us-form {
            margin-top: 5%;
            border: 1px solid var(--cyan-50);
            border-radius: 16px;
            background-color: var(--black-20);
            padding: 24px;
            width: 90%;

            & .cu-input-field {
                padding-bottom: 18px;
                & input { 
                    background-color: transparent;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid var(--acc-blue);
                    outline: transparent;
                    font-size: var(--text-base);
                    width: 92%;
                    padding: 12px 16px 16px;
                }

                & textarea {
                    font-size: var(--text-base);
                    padding: 16px;
                }
            }
        }

        & .success-msg {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;

            border-radius: 12px;
            background: var(--black-10);
            padding: 16px;
            margin-top: 5%;
            .texts {
                & p { margin: 0;}
                & .success-heading {
                    font-weight: 800;
                }
            }
        }
    }
}

@media (max-width: 1024px) {

    .contact-us-page {
    
        .contact-us-main {
            width: 80%;
        }
    }
    
}