.newsfeed-section {
	.newsfeed-section {
		width: 100%; /* Ensure proper alignment */
		display: flex;
		flex-direction: column; /* Stack content vertically */
		gap: 16px; /* Consistent spacing */
	  }	
}

.newsfeed-card {
	font-size: var(--text-base);
  
	p {
	  width: fit-content;
	  margin: 0;
	  padding-bottom: 12px;
	}
  
	& .newsfeed-card-container {
	  display: flex;
	  gap: 16px;
	  margin-bottom: 16px;
	  align-items: flex-start; /* Ensure proper alignment of content and profile image */
	}
  
	/* Profile Picture Container */
	& .user-image {
	  width: 50px;
	  height: 50px;
	  border-radius: 50%; /* Make the profile picture circular */
	  overflow: hidden; /* Ensure image stays within the circle */
	  flex-shrink: 0; /* Prevent shrinking of the container */
	}
  
	& .user-image img {
	  width: 100%;
	  height: 100%;
	  object-fit: cover; /* Maintain aspect ratio and fill the container */
	}
  
	& .post-content {
	  width: 100%;
	  background: var(--deep-blue-10);
	  padding: 16px;
	  position: relative;
	  border-radius: 10px;
	  box-shadow: -8px 8px 0 2px rgba(0, 0, 0, 0.5);
	  border: 1px solid var(--sand-beige-20);
		
	  & .content-text {
		padding: 16px 0;
	  }

	  & .mention-link {
		color: var(--acc-blue);
		text-decoration: none;
		font-weight: bold;
	  }
  
	  & .mention-link:hover {
		text-decoration: underline;
	  }
  
	  & .post-actions {
		display: flex;
		justify-content: space-between;
		margin: 20px 0 0;
  
		.post-data-count {
		  margin: auto 0;
  
		  span {
			color: var(--text-grey);
		  }
		}
  
		.pill {
		  display: flex;
		  gap: 8px;
		}
  
		& .like-comment {
		  background: rgba(255, 255, 255, 0.1);
		  display: flex;
		  gap: 12px;
		  position: absolute;
		  right: 0;
		  bottom: 0;
		  padding: 8px 12px;
		  border-radius: 20px 0 10px 0;
		}
	  }
  
	  & .comment-popup {
		padding-top: 16px;
		display: flex;
		gap: 16px;
		margin-bottom: 42px;
  
		& textarea {
		  border-radius: 99px;
		  padding: 12px 16px 0;
		  background-color: var(--deep-blue-20);
		}
	  }
  
	  & .edit-post-actions {
		display: flex;
		gap: 12px;
  
		button {
		  background-color: transparent;
		  border: none;
		  border-radius: 20px;
		  padding: 6px 12px;
		  font-size: var(--text-md);
		  font-weight: bold;
		  color: var(--ocean-blue);
		  cursor: pointer;
		}
  
		button:hover {
		  text-decoration: underline;
		}
	  }
	}
  
	.no-result {
	  text-align: center;
	  margin: 0 auto;
	  color: var(--text-grey);
	  font-size: var(--text-md);
	}
  
	.time-ago {
	  position: absolute;
	  right: 38px;
	  top: 12px;
	  color: #fff;
	  opacity: 50%;
	}
  
	.post-more-icon {
	  position: absolute;
	  right: 12px;
	  top: 12px;
	  color: #fff;
	  cursor: pointer;
	}
  
	.post-options-modal {
	  position: absolute;
	  right: 12px;
	  top: 38px;
	  color: #fff;
	  width: fit-content;
	  z-index: 99;
  
	  background-color: var(--dark-blue);
	  border-radius: 10px;
  
	  .post-options-list {
		list-style: none;
  
		& li {
		  margin: 0 8px 0 -32px;
		  cursor: pointer;
		  padding: 8px 8px;
		  border-radius: 4px;
  
		  .option-name {
			font-size: var(--text-base);
			margin-left: 12px;
		  }
		}
  
		& li:hover {
		  background-color: var(--black-10);
		}
	  }
	}
  }