.landing-page {
    position: relative;
    
    .lpbg {
        position: fixed;
        width: 100%;
        z-index: -98;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .landing-page-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6%;
        padding-bottom: 10%;
        .lp-heading{
            width: 40%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: fit-content;

            & .submerge-logo {
                width: 300px;
                & img {
                    width: 100%;
                }
            }

            & .tagline {
                font-family: "Zen Dots", serif;
                margin: 0;
                font-size: var(--text-md);
            }
            & .body {
                margin: 32px 0;
                font-size: var(--text-lg);
                color: var(--text-grey);
            }

            & .download-button {
                width: 120px;
                & img {
                    width: 100%;
                    cursor: pointer;
                }
            }

            & .partners-logo {
                padding: 24px 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 16px;
                & img {
                    width: 65px;
                    aspect-ratio: 3/2;
                    object-fit: contain;
                }
            }
        }

        .lp-img {
            width: 30%;
            height: fit-content;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            & .img-mockup {
                width: 350px;

                & img {
                    width: 100%;
                }
            }
        }
    }

    & .lp-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(90deg, var(--black-10), #012c4d);
        z-index: 99;
        padding: 12px 0;

        & .footer-links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 32px;
            .footer-link {
                text-decoration: none;
                font-size: var(--text-md);
                color: var(--text-grey);
            }

            .footer-link:hover {
                color: var(--ocean-blue);
            }
        }

        & .copyright {
            text-align: center;
            color: var(--text-grey);
            font-size: var(--text-sm);
        }


    }
}

@media (max-width: 1024px) {

    .landing-page {
    
        & .landing-page-inner {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 8%;
            padding-bottom: 20%;
            .lp-heading{
                width: 80%;
                text-align: center;
                order: 2;
                & .submerge-logo {
                    width: 200px;
                    margin: 0 auto;
                    & img {
                        width: 100%;
                    }
                }

                & .tagline {
                }
                & .body {
                }
    
                & .download-button {
                    margin: 0 auto;
                    width: 120px;
                }
    
                & .partners-logo {
                    padding: 24px 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    gap: 16px;
                    & img {
                        width: 65px;
                        aspect-ratio: 3/2;
                        object-fit: contain;
                    }
                }
            }
    
            .lp-img {
                width: 90%;
                height: fit-content;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                order: 1;
                & .img-mockup {
                    width: 350px;
    
                    & img {
                        width: 100%;
                    }
                }
            }
        }
    }
    
}