.event-card-container {
    border: 1px solid var(--cyan-20);
    background-color: var(--black-50);
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;

    & .event-card-content {
        & .event-card-image {
            width: 250px;
            height: 250px;
            & img {
                width: 100%;
            }
        }

        & .event-card-details {
            padding: 16px;

            & p { margin: 8px 0 0; }

            .eventcard-eventdate {
                font-weight: bold;
                font-size: var(--text-md);
            }

            .eventcard-eventname {
                font-weight: bold;
                font-size: var(--text-lg);
                color: var(--sand-beige);
            }

            .eventcard-genre {
                font-size: var(--text-sm);
            }
        }

        & .event-card-button {
            padding: 8px 16px 16px;
        }
    }
}