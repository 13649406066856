/* PostPhoto.css */
.post-photo-overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .post-photo-card {
    padding: 24px;
    width: 500px;
    margin: auto;
    background-color: var(--dark-blue);
    border-radius: 10px;
  }
  
  .post-photo-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
  }
  
  .overlay-title {
    margin: 0;
    font-size: 1.25rem;
    color: #fff;
  }
  
  .close-overlay-button {
    cursor: pointer;
  }
  
  .post-photo-form {
    margin-top: 16px;
    color: #fff;
  }
  
  .input-field {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--acc-blue);
    border-radius: 4px;
    margin: 12px 0;
    background-color: transparent;
    color: #fff;
  }
  
  .primary-button {
    background-color: var(--acc-blue);
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 8px;
  }
  
  .primary-button:hover {
    opacity: 0.9;
  }
  
  .upload-status {
    margin-top: 8px;
  }
  
  .thumbnail-preview {
    margin-top: 16px;
  }
  
  .post-photo-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
  
  .modal-cancel {
    background-color: transparent;
    color: var(--acc-blue);
    border: 1px solid var(--acc-blue);
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .modal-cancel:hover {
    background-color: var(--acc-blue);
    color: #fff;
  }