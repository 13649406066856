.error-404-page {
	.bg-img {
        position: fixed;
        width: 100%;
        z-index: -98;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    & .inner-content {
    	padding: 40px;
    	margin-top: 5%;

    	.error-404-img {
    		width: 30%;
    		margin: 0 auto 5%;
    		& img {
    			width: 100%;
            	height: 100%;
    		}
    	}

    	.texts {
    		text-align: center;
    		.heading {
    			font-weight: bold;
    			font-size: var(--text-xl);
    		}

    		.body {
    			font-size: var(--text-base);
    		}

    		.nexpar {
    			margin-top: 3%;
    		}
    	}

    	.logo {
    		width: 200px;
    		margin: 5% auto 0;
    		& .submerge-logo {
    			width: 100%;
    		}
    	}
    }
}