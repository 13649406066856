.tertiary-button {
	margin: auto 0;
	.button {
		background-color: transparent;
		border: none;
		border-radius: 20px;
		padding: 6px 0;
		height: fit-content;
		width: fit-content;
		font-size: var(--text-md);
		font-weight: bold;
		color: var(--ocean-blue);
	}
}