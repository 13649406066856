.profile-panel {
	min-width: 20%;
	padding: 24px;
  	background-color: var(--deep-blue-10);
  	background-size: cover;
  	background-position: right;
  	border-radius: 10px;
  	height: fit-content;
	animation: fadeSlide 0.3s ease-out forwards; /* Apply the animation */
	border: 1px solid var(--sand-beige-10);

	.profile-panel-content {

		text-align: center;

		img {
			width: 120px;
			height: 120px;
			background-size: cover;
			border-radius: 100%;
			background-color: var(--text-grey);
		}

		.user-overview {
			h3 { margin-bottom: 8px; }
			p { margin: 8px 0; }
			.overview-user-handle { color: var(--text-grey); }
			.overview-ff-count { 
				display: flex;
				justify-content: space-around;
			}

			.ov-followers-count { font-weight: bold; }
			.ov-following-count { font-weight: bold; }
			.ov-ff-label {
				font-size: var(--text-sm);
				color: var(--text-grey);
			}
		}

	}
}