.sign-up {
	background-image: url('../assets/sign_bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100vh;
}

.sign-up h1, .sign-up h5 {
	margin: 0;
	text-align: center;
}

.sign-up .sign-up-container {
	height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.sign-up .sign-up-container .left-panel {
	width: 50%;
	height: fit-content;
}

.sign-up .sign-up-container .left-panel .logo {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.sign-up .sign-up-container .left-panel .logo .submerge-logo {
	width: 80%;
}

.sign-up .sign-up-container .left-panel .logo .tagline {
	margin: 0 auto;
	text-align: center;
	font-size: var(--text-md);
	font-family: "Zen Dots", serif;
}

.sign-up .sign-up-container .right-panel {
	width: 50%;
	justify-content: center;
}

.sign-up .sign-up-container .right-panel .sign-up-card {
	margin: 0 auto;
	padding: 24px;
	background-color: var(--black-10);
	border-radius: 20px;
	width: 70%;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .error-message .error-text {
	color: var(--error);
	font-size: var(--text-sm);
	text-align: center;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-fields-section .terms-privacy {
	display: flex;
	padding: 16px 0;
	gap: 8px;
	font-size: var(--text-md);
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-fields-section .terms-privacy .terms-privacy-checkbox {
	width: fit-content;
	margin: auto 0;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-fields-section .terms-privacy span {
	font-size: var(--text-md);
	padding-left: 4px;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-fields-section .terms-privacy span .link-to-termsprivacy { 
	text-decoration: none;
	color: var(--acc-blue);
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-fields-section .terms-privacy label {
	width: fit-content;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .label {
	font-size: var(--text-sm);
	font-weight: bold;
	color: var(--text-grey);
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .form-field { 
	padding-top: 24px; 
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .form-field .form-input {
	background-color: transparent;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 1px solid var(--acc-blue);
	outline: transparent;
	font-size: var(--text-base);
	width: 92%;
	padding: 0 16px 16px;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-error-message .error-text {
	font-size: var(--text-sm);
	color: var(--error);
	margin-bottom: 0;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .input-error-message .success-text {
	font-size: var(--text-sm);
	color: var(--success);
	margin-bottom: 0;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .password-field, .sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .reenter-password-field {
	display: flex;
	position: relative;
	width: 100%;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .password-field .toggle-password-icon, .sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .reenter-password-field .toggle-reenter-password-icon {
	position: absolute;
	top: 50%;
	right: 60px;
	transform: translateY(-50%);
	cursor: pointer;
	color: var(--text-grey);
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .form-buttons {
	padding: 16px 0 0;
	gap: 16px;
	width: 100%;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .switch-form-texts {
	display: flex;
	gap: 4px;
	font-size: var(--text-sm);
	justify-content: center;
	padding: 16px 0 0;
}

.sign-up .sign-up-container .right-panel .sign-up-card .sign-up-form .switch-form-texts .link-to-signin {
	color: var(--acc-blue);
	margin: auto 0;
	text-decoration: none;
}

/*STYLE SCREEN IS <853px */
@media (max-width: 913px) {
	.sign-up .sign-up-container {
		padding-top: 10%;
		flex-direction: column;
		align-items: center;
		gap: 64px;
	}

	.sign-up .sign-up-container .left-panel {
		width: 100%;
	}

	.sign-up .sign-up-container .right-panel {
		width: 100%;
	}
}

.modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: var(--black-0);
    padding: 32px;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.modal-header .title {
    font-size: var(--text-lg);
    font-weight: bold;
    color: var(--text-white);
}

.modal-header .close-button {
    background: none;
    border: none;
    font-size: 24px;
    color: var(--text-white);
    cursor: pointer;
}

.avatar-editor-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
}

.modal-buttons .button {
    padding: 8px 16px;
    font-size: var(--text-base);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    background-color: var(--cyan);
    color: white;
}