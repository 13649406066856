.header-web {
	justify-content: space-between;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding: 16px;
	min-height: 48px;

	.live-radio-button {
		display: flex;
		gap: 8px;

	}

	.link {
		text-decoration: none;
		padding: 0;
		margin: auto 0;


		& h5 {
			padding: 0;
			margin: 0;
		}
	}
	.left-col {
		display: flex;
	}

	.submerge-logo {
		width: 120px;
		height: 30px;
		margin: auto;
	}

	.submerge-logo-min {
		display: none;
	}

	& .header-search {
		border-left: 2px solid var(--dark-blue);
		--webkit-border-left: 2px solid var(--dark-blue);
		padding: 8px 16px;
		min-width: 20%;
		flex-direction: row;
		align-items: center;
		gap: 8px;

		.header-search-input {
			background-color: transparent;
		    font-size: var(--text-md);
		    width: 100%;
		    color: white;
		    border: none; /* Ensures no unwanted default borders */
		    padding: 8px; /* Adds some space for better UI */
		    appearance: none; /* Normalizes across browsers */
		    -webkit-appearance: none; /* Safari-specific */
		    -moz-appearance: none;
			&:focus {
				outline: none;
			}

			::placeholder {
				color: var(--text-grey);
			}
		}

		.search-icon {
			width: fit-content;
			margin: 5px 0 0;
		}
	}

	& h5 { margin:0; }

	.right-buttons-web { 
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px;
		position: relative;

		.radio-player-header {
			max-width: fit-content;
		}

		& .radio-switch {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 8px;
			padding: 8px 16px;
			border-radius: 99px;
			cursor: pointer;
			width: 130px;
			height: 24px;
		}

		& .toggleRadioOff {
			border: 1px solid var(--cyan-20);
			background-color: var(--deep-blue-10);
			& .switch-icon {
				width: 14px;
				& img {
					margin-top: 2px;
				}
			}

			& h4 { margin: 0; }
			& .text-def {
				
			}

			& .text-min{
				display: none;
			}
		}

		& .toggleRadioOn {
			box-shadow: var(--bubble-pill-inset);
			border: 1px solid var(--cyan);
			background-color: var(--cyan-20);
			& .switch-icon {
				width: 22px;
				& img {
					margin-top: 2px;
				}
			}

			& h4 { margin: 0; }
			& .text-def {
				
			}

			& .text-min{
				display: none;
			}
		}

		.right-button {
			margin: auto 0;
			position: relative;

			& .indicator {
				width: 8px;
				height: 8px;
				min-width: 8px;
				min-height: 8px;
				background-color: var(--acc-blue);
				position: absolute;
				right: 0;
				border-radius: 99px
			}
		}

		& .notification-popup-modal {
			position: absolute;
			right: 0;
			top: 30px;
		}
	}
}




/* RESPONSIVE */

/* STYLE SCREEN IS >820px */
@media (min-width: 821px) {

	.header-mobile { display: none; }

}

/* STYLE SCREEN IS <820px */
@media (max-width: 820px) {

	.header-mobile { display: none; }
	.header-web {
		.submerge-logo {
			display: none !important;
		}

		.submerge-logo-min {
			display: block;
			width: 50px;
			height: 20px;
			margin: auto;
		}

		.right-buttons-web { 

			& .radio-switch {
				width: 80px;
				padding: 8px 10px;
				height: 18px;
			}

			& .toggleRadioOff {
				& .switch-icon {
					width: 14px;
					& img {
						margin-top: 2px;
					}
				}
				& .text-def {
					display: none !important;
				}

				& .text-min{
					display: block !important;
					font-size: var(--text-sm);
				}
			}

			& .toggleRadioOn {
				& .switch-icon {
					width: 14px;
					& img {
						margin-top: 2px;
					}
				}
				& .text-def {
					display: none;
				}

				& .text-min{
					display: block;
					font-size: var(--text-sm);
				}
			}
		}
	}

}