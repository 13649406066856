.edit-profile-information {
	width: 100%;
  }
  
  .edit-profile-form {
	background: linear-gradient(180deg, rgba(0,46,81,0.5) 0%, rgba(0,0,0,0.50) 50%);
	padding: 32px;
	border-radius: 20px;
	gap: 24px;
  }
  
  .profile-mobile-userphoto {
	width: 100px;
	height: 100px;
	-webkit-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
	-moz-box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
	box-shadow: 0px 0px 64px 4px rgba(65,156,255,0.3);
	border-radius: 100%;

	& .header-avatar {
		width: 100px;
		height: 100px;
		border-radius: 100%;
		border: 0.2px solid var(--main-blue);

		background-size: contain;
		background-position: center;
	  }
  }
  

  
  .input-fields-section {
	width: 100%;
  }
  
  .form-field {
	padding-bottom: 24px;
  }
  
  .label {
	font-size: var(--text-sm);
	font-weight: bold;
	color: var(--text-grey);
  }
  
  & .input-field {
	background-color: transparent;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	border-bottom: 1px solid var(--acc-blue);
	outline: transparent;
	font-size: var(--text-base);
	width: 92%;
	padding: 12px 16px 16px;
  }
  
  .form-buttons {
	display: flex;
	justify-content: end;
	gap: 16px;
  }
  
  .form-buttons .button {
	width: 100%;
  }

  .button-saved {
	opacity: 0.8;
	transition: opacity 0.3s ease-in-out;
  }
  
  /* STYLE SCREEN IS >820px */
  @media (min-width: 821px) {
	.edit-profile-form {
	  display: flex;
	}
  }
  
  /* STYLE SCREEN IS <820px */
  @media (max-width: 820px) {
	.edit-profile-information {
	  width: 100%;
	}
  
	.edit-profile-form {
	  display: block;
	}
  
	.profile-mobile-userphoto {
	  margin: 24px auto 48px;
	}
  }
  
  /* Modal styling */
	.modal {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		z-index: 1000;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: auto;
		background-color: rgba(0, 0, 0, 0.7); 

		/* Modal content styling */
		.modal-content {
			background-color: var(--page-background-color, #002e51); 
			color: rgba(0, 0, 0, 0.7); 
			margin: 24px;
			padding: 30px;
			width: 80%;
			max-width: fit-content;
			border-radius: 12px;
			text-align: center;
			box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); 

			.modal-header {
				display: flex;
				justify-content: space-between;

				.title { font-size: var(--text-base); font-weight:bold; }
			}

			.filepond--wrapper {
				height: 500px;
				width: 500px;
			}

			.filepond--hopper {
				height: 100% !important;
			}

			.filepond--drop-label {
				height: 100%;
				background-color: var(--dark-blue);
			}

			.filepond--credits { display:none; }

			.modal-buttons {
				display: flex;
				justify-content: center;
				gap: 16px;
				padding: 16px 0;
				width: 100%;

				& .button {
					background-color: var(--main-blue);
					border: 1px solid var(--main-blue);
					border-radius: 20px;
					padding: 8px 16px;
					height: fit-content;
					width: 100px;
					font-size: var(--text-sm);
					font-weight: bold;
				}
			}
			

		}

  }
  
  
  /* Close button styling */
  .close-button {
	color: #41a0ff; /* Bright blue for visibility */
	font-size: 28px;
	font-weight: bold;
	cursor: pointer;
	background-color: transparent;
	border: none;
	outline: none;
	padding: 0 15px;
	margin: -15px -15px 0 0;
	border-radius: 50%;
	transition: background-color 0.3s, color 0.3s;
  }
  
  /* Style the clickable portion of the label (e.g., "Browse") */
  .filepond--label-action {
	color: var(--acc-blue); /* Color for clickable action text */
	cursor: pointer; /* Pointer cursor on hover */
	text-decoration: none; /* Underline to indicate action */
	font-weight: 600; 
	font-size: 16px; 
  }