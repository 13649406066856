.sign-in {
	background-image: url('../assets/sign_bg.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 100vh;


	& h1, h5 {
		margin: 0;
		text-align: center;
	}

	& .sign-in-container {
		height: 100vh;
		display: flex;
		flex-direction: row;
		align-items: center;

		& .left-panel {
			width: 50%;
			height: fit-content;

			& .logo {
				display: flex;
				flex-direction: column;
				align-items: center;

				& .submerge-logo {
					width: 80%;
				}

				& .tagline {
					margin: 0 auto;
					text-align: center;
					font-size: var(--text-md);
					font-family: "Zen Dots", serif;
				}
			}
		}

		& .right-panel {
			width: 50%;
			justify-content:center;

			.sign-in-card {
				margin: 0 auto;
				padding: 24px;
				background-color: var(--black-10);
				border-radius: 20px;
				width: 70%;

				& .sign-in-form {

					.error-message {
						.error-text {
							color: var(--error);
							font-size: var(--text-sm);
							text-align: center;
						}
					}
					.input-fields-section {
						.rememberme-forgotpass {
							display: flex;
							padding: 16px 0;
							justify-content: space-between;
							font-size: var(--text-md);

							.rememberme {
								width: 50%;
								display: flex;
								gap: 8px;

								.remember-me-checkbox {
									width: fit-content;
									margin: auto 0;
								}

								label {
									width: fit-content;

								}
							}

							.forgotpassword {
								.forgotpassword-label { color: var(--acc-blue); }
							}
						}
					}

					

					& .label {
						font-size: var(--text-sm);
						font-weight: bold;
						color: var(--text-grey);
					}

					.form-field { 
						padding-top: 24px; 
					}

					.password-field {
						display: flex;
						position: relative;

						.toggle-password-icon {
							position: absolute;
							bottom: 10px;
							right: 8px;
						}
					}

					& .form-input {
						background-color: transparent;
						border-top: 0;
						border-left: 0;
						border-right: 0;
						border-bottom: 1px solid;
						outline: transparent;
						font-size: var(--text-base);
						width: 90%;
						padding: 8px 16px 16px;
					}

					.form-buttons {
						padding: 16px 0 0;
						gap: 16px;
						width: 100%;

					}

					.switch-form-texts {
						display: flex;
						gap: 4px;
						font-size: var(--text-sm);
						justify-content: center;
						padding: 16px 0 0;

						.link-to-signup {
							color: var(--acc-blue);
							margin: auto 0;
							text-decoration: none;
						}
					}

				}
			}
		}
	}
}

/*STYLE SCREEN IS <853px */
@media (max-width: 913px) {

	.sign-in {

		& .sign-in-container {

			padding-top: 10%;

			flex-direction: column;
			align-items: center;
			gap: 64px;

			& .left-panel {
				width: 100%;
			}

			& .right-panel {
				width: 100%;
			}
		}
	}
	
}