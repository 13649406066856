.radio-player-component {
    position: fixed;
    bottom: 24px;
    right: 4px;
    z-index: 50;
    height: fit-content;
    width: 430px;
    /* background-color: var(--dark-blue); */
    border-radius: 24px;
    padding: 16px;

    & .radio-player-container {
        position: relative;
        border-radius: 20px;
        border: 1px solid var(--cyan-50);
        /* background: linear-gradient(145deg, #012541, #012c4d);
        box-shadow:  5px 5px 12px #011d32,
             -5px -5px 12px #01355e; */

        background-color: var(--dark-blue-50);
        
        
        & .radio-player-image {
            width: 120px;
            height: 120px;
            position: absolute;
            left: 16px;
            top: -24px;
            animation: spin 30s infinite linear;
        }

        & .radio-music-info {
            padding: 8px 16px 8px 160px;

            & p { margin: 0; }

            & .radio-channel { 
                

                display: flex;

                & .radio-channel-name {
                    font-size: var(--text-sm); 
                    font-weight: bold;
                    color: var(--text-grey);
                }

                & .live-dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    margin: auto 4px auto 0;
                    background-color: var(--red);
                }
            }

            & .radio-now-playing {
                padding: 4px 0;
                font-size: var(--text-base);

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                & p { font-size: var(--text-base);}
            }
        }

        & .radio-controls {
            padding: 8px 16px 8px 120px;
            background-color: var(--deep-blue-50);
            border-radius: 0 0 20px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 16px;

            & .skip-button {
                cursor: pointer;

            }

            & .play-button, .stop-button {
                padding: 6px 8px;
                cursor: pointer;
                /* border-radius: 20px;
                background: linear-gradient(145deg, #012541, #012c4d);
                box-shadow:  5px 5px 12px #011d32,
                    -5px -5px 12px #01355e; */
            }
        }
    }
}
