
/* STYLE SCREEN IS >820px */
@media (min-width: 821px) {
	.single-post-overlay {
		z-index: 99;
		position: fixed;
		background: rgba(0, 0, 0, .5);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 60px;		

		& .overlay-content {
			font-size: var(--text-base) ;
	  		background-image: url('../assets/desktopbg.png');
			padding: 8px 16px 16px;
			border-radius: 12px;
			width: 600px;
			height: fit-content;
			max-height: 85vh;
			margin: auto;
			animation: fadeSlideUp 0.2s ease-in forwards; /* Apply fade-in animation */

			overflow-y: scroll;
			position: relative;
			 .container-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				padding-top: 16px;

				& p { 
					font-size: var(--text-md); 
					font-weight: bold;
					padding: 0;
				}
			}

			& .single-post-content {
				padding: 8px 0 0;
				position: relative;
				& .posted-by {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 16px;
					padding-bottom: 16px;

					.posted-by-image {
						height: 40px;
						width: 40px;

						& img {
							width: 100%;
							height: 100%;
							border-radius: 99%;

						}
					}

					& .posted-by-details {
						width: fit-content;

						.posted-by-user {
							display: flex;
							flex-direction: row;
							align-items: center;
							gap: 16px;

							.posted-by-name {
								padding-bottom: 0;
								font-size: var(--text-base);
								font-weight: bold;
								margin: auto 0;
							}

							& span { margin: auto 0; }

							.follow-button {
								margin: auto 0;
							}
						}

						.posted-by-date {
							font-size: var(--text-sm);
							color: var(--text-grey);
						}
					}
				}
				.post-more-icon {
							position: absolute;
							right: 12px;
							top: 12px;
							color: #fff;
						}

						.post-options-modal {
							position: absolute;
							right: 12px;
							top: 38px;
							color: #fff;

							box-shadow: 0 0 22px -8px rgba(0,0,0,0.5);;
							width: fit-content;
							z-index: 99;

							background-color: var(--dark-blue);
							border-radius: 10px;

							.post-options-list {
								list-style: none;

								& li {
									margin: 0 8px 0 -32px;
									cursor: pointer;
									padding: 8px 8px;
									border-radius: 4px;

									.option-name {
										font-size: var(--text-base);
										margin-left: 12px;
									}
								}

								& li:hover {
									background-color: var(--black-10);
									

								}

							}
						}
				& .post-actions-modal {
					justify-content: space-between;
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					height: fit-content;

					& .post-data-count {
						margin: auto 0;
						& span {
							color: var(--text-grey);
						}
					}

					& .like-comment-modal {
						background: rgba(255, 255, 255, 0.1);
						display: flex;
						gap: 12px;
						padding: 8px 14px;
						border-radius: 20px;
					}
				}
				
			}

			& .comment-section {

				& .single-comment {
					& .commented-by {
						display: flex;
						gap: 16px;
						padding-bottom: 8px;
						width: 100%;

						.commented-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}					
					
						/* Reply Options Modal */
												
						.commented-by-details {
							width: 100%;
							padding: 8px 18px 18px;
							border-radius: 10px;
							background-color: var(--black-10);

							.commented-by-user {
								display: flex;
								flex-direction: column; 
								gap: 4px; 
							}

							.commented-by-name {
								font-size: var(--text-sm);
								font-weight: bold;
								margin: auto 0;
							}

							.commented-by-date {
								font-size: var(--text-xs);
								color: var(--text-grey);
								margin: 0;
							}

							& span { margin: auto; }

							.follow-button {
								margin: auto 0;
							}
						}

							.comment-body {
								font-size: var(--text-md);
							}

							
							
					}
				
					& .reply-options-modal {
						position: absolute;
						right: 10px;
						top: 25px;
						background-color: var(--dark-blue);
						border-radius: 6px;
						box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
						z-index: 10;
						width: max-content;
						padding: 8px 0;

						.reply-options-list {
							list-style: none;
							padding: 0;
							margin: 0;
						}
					
						.reply-options-list li {
							padding: 6px 12px;
							cursor: pointer;
							font-size: var(--text-base);
							border-radius: 4px;
						}
					
						.reply-options-list li:hover {
							background-color: var(--black-10);
						}
					}
														
					& .reply-by {
						display: flex;
						align-items: center;
						gap: 12px;
					}
				
					& .reply-by img {
						width: 32px;
						height: 32px;
						border-radius: 50%;
					}
				
					& .reply-by-details {
						display: flex;
						flex-direction: column;

						.reply-more-icon {
							position: absolute;
							right: 10px;
							top: 5px;
							cursor: pointer;
							color: var(--text-grey);
						}
					}
				
					& .reply-by-name {
						font-size: var(--text-sm);
						font-weight: bold;
					}
				
					& .reply-by-date {
						font-size: var(--text-xs);
						color: var(--text-grey);
					}
				
					& .reply-body {
						font-size: var(--text-md);
						margin-top: 4px;
					}
				
					& .nested-reply {
						margin-left: 60px; /* Further indent nested replies */
						border-left: 2px solid var(--black-10);
						padding-left: 12px;
					}
				
					& .reply-input {
						display: flex;
						flex-direction: column;
						gap: 8px;
						margin: 10px 0 32px 0;
						padding: 10px;
						border-radius: 8px;
						background-color: var(--black-10);
					}
				
					& .reply-input textarea {
						width: 95%;
						min-height: 40px;
						padding: 6px 10px;
						border: 1px solid var(--dark-blue);
						border-radius: 6px;
						background: var(--black-5);
						font-size: var(--text-md);
					}
				
					& .reply-buttons {
						display: flex;
						gap: 8px;
					}
				
					& .reply-buttons button {
						padding: 6px 12px;
						border-radius: 6px;
						cursor: pointer;
						font-size: var(--text-sm);
						border: none;
					}
				
					& .reply-buttons .save-reply {
						background-color: var(--blue);
						color: white;
					}
				
					& .reply-buttons .cancel-reply {
						background-color: var(--black-20);
						color: white;
					}
				}

					& .reply-actions {
						/* margin-bottom: 8px;
						margin-left: 72px; */
						display: flex;
						justify-content: space-between;

						& .show-sub-replies {
						}

						& .reply-button-container {
							right: 12px;
							bottom: 12px;
							display: flex;

							.icon {
								margin: auto 4px;
							}
						}
					}

					

					& .nested-comment-by {
						padding-left: 58px;
						display: flex;
						gap: 16px;
						padding-bottom: 16px;

						.nested-comment-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}

						.nested-comment-by-details {
							width: 100%;
							padding: 8px 18px;
							border-radius: 10px;
							background-color: var(--black-10);

							.nested-comment-by-user {
								display: flex;
								gap: 16px;
								justify-content: space-between;
								padding-bottom: 8px;

								.nested-comment-by-name {
									padding-bottom: 0;
									font-size: var(--text-sm);
									font-weight: bold;
									margin: auto 0;
								}

								.nested-comment-by-date {
									font-size: var(--text-xs);
									color: var(--text-grey);
									margin: auto 0;
								}

								& span { margin: auto; }

								.follow-button {
									margin: auto 0;
								}
							}

							.comment-body {
								font-size: var(--text-md);
							}
							
						}
					}

					& .nested-reply-by {
						display: flex;
						gap: 16px;
						margin-left: 58px;
						background-color: var(--black-10);
						border-radius: 20px;
						padding: 10px;

						.nested-reply-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}

						& .post-comment-button {
							margin: auto;
						}
					}
				}
			}

			}
			& .create-comment {
				display: flex;
				gap: 16px;
				justify-content: space-between;

				background-color: var(--black-10);
				border-radius: 20px;
				padding: 0 10px;

				.comment-user-image {
					min-height: 30px;
					min-width: 30px;
					height: 30px;
					width: 30px;
					margin: auto 0;

					& img {
						width: 100%;
						height: 100%;
						border-radius: 99%;

					}
				}

				& .post-content {
					padding: 8px 0 0;
					font-size: var(--text-md);
					margin: auto 0;
				}

				& .post-comment-button {
					margin: auto;
				}
			}
		}
		

		& .overlay-content-mobile {
	/*		background: var(--dark-blue); */
	  		background-image: url('../assets/desktopbg.png');
	  		background-position: bottom;
			padding: 16px;
			border-radius: 12px 12px 0 0;
			height: 100%;

			.container-header {
				display: flex;
				justify-content: space-between;
				font-weight: bold;
			}

			.compose-section {
	  			background-image: url('../assets/desktopbg.png');
	  			background-position: right;
				padding: 16px;
				margin: 16px auto;
				border-radius: 8px;
			}
			.compose-area {
				display: flex;
			}

			textarea { 
				padding: 0 16px;
				font-size: var(--text-md);
			}
			.post-actions {
				justify-content: space-between;
				width: 100%;
				display: flex;

				.add-content {
					gap: 8px;
					display: flex;
				}
			}
		}
		
	


/* STYLE SCREEN IS <768px */
@media (max-width: 820px) {
	.single-post-overlay {
		z-index: 99;
		position: absolute;
		background: rgba(0, 0, 0, .5);
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		& .overlay-content {
			font-size: var(--text-base) ;
	/*		background: var(--dark-blue);*/
	  		background-image: url('../assets/desktopbg.png');
			padding: 8px 16px 14px;
			border-radius: 0;
			height: 100%;
/*			margin: auto;*/

			& .container-header {
				display: flex;
				justify-content: space-between;
				padding-top: 16px;

				& p { 
					font-size: var(--text-md); 
					font-weight: bold;
				}
			}

			& .post-content {
				padding: 16px 0 0;
				& .posted-by {
					display: flex;
					gap: 16px;
					padding-bottom: 16px;

					.posted-by-image {
						height: 40px;
						width: 40px;

						& img {
							width: 100%;
							height: 100%;
							border-radius: 99%;

						}
					}

					& .posted-by-details {
						width: fit-content;

						.posted-by-user {
							display: flex;
							gap: 16px;

							.posted-by-name {
								padding-bottom: 0;
								font-size: var(--text-base);
								font-weight: bold;
								margin: auto 0;
							}

							& span { margin: auto 0; }

							.follow-button {
								margin: auto 0;
							}
						}

						.posted-by-date {
							font-size: var(--text-sm);
							color: var(--text-grey);
						}
					}
				}
				& .post-actions {
					justify-content: space-between;
					width: 100%;
					display: flex;
					position: relative;

					& .post-data-count {
						margin: auto 0;
						& span {
							color: var(--text-grey);
						}
					}

					& .like-comment {
						background: rgba(255, 255, 255, 0.1);
						display: flex;
						gap: 24px;
						position: absolute;
						right: 0;
						bottom: 0;
						padding: 8px 14px;
						border-radius: 20px;
					}
				}
				
			}

			& .comment-section {

				& .single-comment {

					& .commented-by {
						display: flex;
						gap: 16px;
						padding-bottom: 8px;
						width: 100%;

						

						.commented-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}

						

						.commented-by-details {
							width: 100%;
							padding: 8px 18px 18px;
							border-radius: 10px;
							background-color: var(--black-10);
							position: relative;
							
							.comment-more-icon {
								position: absolute;
								right: 10px;
								top: 5px;
								cursor: pointer;
								color: var(--text-grey);
							}		

							.commented-by-user {
								position: relative;  /* Allows absolute positioning inside */
								display: flex;
								align-items: center;
								justify-content: space-between; /* Ensures spacing */
								width: 100%;

								.commented-by-name {
									padding-bottom: 0;
									font-size: var(--text-sm);
									font-weight: bold;
									margin: auto 0;
								}

								.commented-by-date {
									font-size: var(--text-xs);
									color: var(--text-grey);
									margin: auto 0;
								}

								& span { margin: auto; }

								.follow-button {
									margin: auto 0;
								}

								
							
								/* Comment Options Modal */
								.comment-options-modal {
									position: absolute;
									right: 10px;
									top: 25px;
									background-color: var(--dark-blue);
									border-radius: 6px;
									box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
									z-index: 10;
									width: max-content;
									padding: 8px 0;
								}
							
								.comment-options-list {
									list-style: none;
									padding: 0;
									margin: 0;
								}
							
								.comment-options-list li {
									padding: 6px 12px;
									cursor: pointer;
									font-size: var(--text-base);
									border-radius: 4px;
								}
							
								.comment-options-list li:hover {
									background-color: var(--black-10);
								}
							}

							.comment-body {
								font-size: var(--text-md);
							}

							
							
						}
					}

					& .reply-actions {
						/* margin-bottom: 8px; */
						/* margin-left: 72px; */
						display: flex;
						justify-content: space-between;

						& .show-sub-replies {
						}

						& .reply-button-container {
							right: 12px;
							bottom: 12px;
							display: flex;

							.icon {
								margin: auto 4px;
							}
						}
					}

					

					& .nested-comment-by {
						padding-left: 58px;
						display: flex;
						gap: 16px;
						padding-bottom: 16px;

						.nested-comment-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}

						.nested-comment-by-details {
							width: 100%;
							padding: 8px 18px;
							border-radius: 10px;
							background-color: var(--black-10);

							.nested-comment-by-user {
								display: flex;
								gap: 16px;
								justify-content: space-between;
								padding-bottom: 8px;

								.nested-comment-by-name {
									padding-bottom: 0;
									font-size: var(--text-sm);
									font-weight: bold;
									margin: auto 0;
								}

								.nested-comment-by-date {
									font-size: var(--text-xs);
									color: var(--text-grey);
									margin: auto 0;
								}

								& span { margin: auto; }

								.follow-button {
									margin: auto 0;
								}
							}

							.comment-body {
								font-size: var(--text-md);
							}
							
						}
					}

					& .nested-reply-by {
						display: flex;
						gap: 16px;
						margin-left: 58px;
						background-color: var(--black-10);
						border-radius: 20px;
						padding: 10px;

						.nested-reply-by-image {
							min-height: 40px;
							min-width: 40px;
							height: 40px;
							width: 40px;

							& img {
								width: 100%;
								height: 100%;
								border-radius: 99%;

							}
						}

						& .post-content {
							padding: 8px 0 0;
							font-size: var(--text-md);
							margin: auto 0;
						}

						& .post-comment-button {
							margin: auto;
						}
					}
				}
			}

			& .divider-bottom { display: none; }


			& .create-comment {
				position: fixed;
				right: 0;
				left: 0;
				bottom: 0;
				display: flex;
				gap: 16px;
				justify-content: space-between;

				background-color: var(--black-10);
				border-radius: 20px 20px 0 0;
				padding: 26px;

				.comment-user-image {
					min-height: 30px;
					min-width: 30px;
					height: 30px;
					width: 30px;
					margin: auto 0;

					& img {
						width: 100%;
						height: 100%;
						border-radius: 99%;

					}
				}

				& .post-content {
					padding: 8px 0 0;
					font-size: var(--text-md);
					margin: auto 0;
				}

				& .post-comment-button {
					margin: auto;
				}
			}
		}
		

		& .overlay-content-mobile {
	/*		background: var(--dark-blue); */
	  		background-image: url('../assets/desktopbg.png');
	  		background-position: bottom;
			padding: 16px;
			border-radius: 12px 12px 0 0;
			height: 100%;

			.container-header {
				display: flex;
				justify-content: space-between;
				font-weight: bold;
			}

			.compose-section {
	  			background-image: url('../assets/desktopbg.png');
	  			background-position: right;
				padding: 16px;
				margin: 16px auto;
				border-radius: 8px;
			}
			.compose-area {
				display: flex;
			}

			textarea { 
				padding: 0 16px;
				font-size: var(--text-md);
			}
			.post-actions {
				justify-content: space-between;
				width: 100%;
				display: flex;

				.add-content {
					gap: 8px;
					display: flex;
				}
			}
		}
		
	}
}

