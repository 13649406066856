.track-post-component {
    padding: 12px;
    background-color: var(--black-20);
    border-radius: 12px;
    margin: 4px 0 12px;
    /* width: fit-content; */
    & .track-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        & .track-thumbnail {
            width: 100px;
            height: 100px;
            & img {
                width: 100%;
            }
        }
        
        & .track-player {
            padding: 0 16px;
            width: 80%;
            & .track-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                .track-deets {
                    & p { padding: 4px 0;}
                    & .track-title-artist {
                        font-size: var(--text-base);
                        font-weight: bold;
                    }
                    & .track-genre {
                        font-size: var(--text-sm);
                        color: var(--text-grey);
                    }
                }
    
                & .play-pause {
                    padding: 0 0 0 24px;
                }
            }
    
            & .slider {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding: 12px 0 4px;
                & .progress-bar {
                    width: 100%;
                    height: 3px;
                    appearance: none;
                    border-radius: 2px;
                    
                }

                .progress-bar::-webkit-progress-bar {
                    background-color: var(--text-grey); /* Background */
                    border-radius: 5px;
                  }
                  
                  .progress-bar::-webkit-progress-value {
                    background-color: var(--cyan); /* Progress color */
                    border-radius: 5px;
                  }
                  
                  .progress-bar::-moz-progress-bar {
                    background-color: var(--cyan);
                  }
            }
        }
    }
}