.user-card {
	height: fit-content;
	padding: 0;
	margin: 0 0 8px;
	width: 100%;
	& .user-card-container { 
		background-color: var(--black-20);
		border-radius: 8px;
		text-align: center;
		padding: 4px 12px;
		display: flex;
		justify-content: space-between;

		& .avatar-name {
			display: flex;
			gap: 16px;
			& .user-image-card {
				margin: auto 0;
				min-width: 45px;
				width: 45px;
				height: 45px;
				min-height: 45px;
				border-radius: 100%;
				background-color: rgba(255,255,255,0.2);
	
				& img {
					width: 100%;
					height: 100%;
					border-radius: 100%;
	
				  }
			}

			& .user-handle-name {
				& .user-name-card {
					font-size: var(--text-md);
					font-weight: bold;
					margin-bottom: -6px;
					text-align: left;
				}
		
				& .user-handle-card {
					font-size: var(--text-sm);
					color: var(--text-grey);
					text-align: left;
				}
			}
		}

		& .button {
			margin: auto 0;
		}
	}
}

/*STYLE SCREEN IS <820px */
@media (max-width: 1180px) {

	.user-card {

		& .user-card-container { 
			height: fit-content;
			padding: 4px 12px 12px;
			display: block;
			gap: 16px;
			& .avatar-name {
				& .user-image-card {
		
					& img {
						
		
					  }
				}

				& .user-handle-name {
					& .user-name-card {
						display: inline-block;
						max-width: 10ch; /* Approximate character limit */
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
			
					& .user-handle-card {
						max-width: 10ch; /* Approximate character limit */
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
			}

			& .button {
			}
		}
	}
	
}