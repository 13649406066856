.edit-email-password {
	width: 100%;

	.edit-emailpass-form {
		background: linear-gradient(180deg, rgba(0,46,81,0.5) 0%, rgba(0,0,0,0.50) 50%);
		padding: 24px;
		border-radius: 20px;

		.form-field {
			padding-bottom: 24px;
		}

		.label {
			font-size: var(--text-sm);
			font-weight: bold;
			color: var(--text-grey);
		}

		& input {
			background-color: transparent;
			border-top: 0;
			border-left: 0;
			border-right: 0;
			border-bottom: 1px solid var(--acc-blue);
			outline: transparent;
			font-size: var(--text-base);
			width: 93%;
			padding: 0 16px 16px;
		}

		.form-buttons {
			display: flex;
			justify-content: flex-end;
			gap: 16px;
		}

		.form-buttons .button {
			width: 50%;
		}
	}
}




/* STYLE SCREEN IS >820px */
@media (min-width: 821px) {
	.edit-email-password {
		width: 100%;
	}
	
	.edit-emailpass-form {
		display: block;
	}
}

/* STYLE SCREEN IS <820px */
@media (max-width: 820px) {
	.edit-email-password {
		width: 100%;
	}

	.edit-emailpass-form {
		display: block;
	}
}