.radio-page {
	display: flex;
	margin: 80px 40px;

	& .radio-stations-carousel {
    	max-width: 95%;

    	& .station-description {
    		margin: 20px 40px;
    		background-color: var(--black-20);
    		padding: 20px;
    		border-radius: 12px;
    		display: flex;
    		gap: 20px;

    		.station-name {
    			color: var(--cyan);
    		}
    	}

    	& .whats-up {
    		margin: 20px 40px;
			background-color: var(--black-20);
    		padding: 20px;
    		border-radius: 12px;

			.title {
				font-weight: bold;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 8px;
				width: fit-content;

				& p { margin: 0; font-size: var(--text-md); }
			}

			& .tooltip {
				position: absolute;
				margin-top: 8px;
				padding: 8px;
				border-radius: 8px;
				background-color: var(--dark-blue);

				& p { font-size: var(--text-sm); margin:0; }
			}

			& .title:hover .tooltip {
				visibility: visible;
			}
		}
	}
	& .right-panel {
		width: 40%;

		& .radio-page-player {
			border: 1px solid var(--cyan-50);
			padding: 12px 40px;
			border-radius: 20px;
			background-color: var(--black-20);
			height: fit-content;

			& .now-playing {
				text-align: center;
				font-weight: bold;
				font-size: var(--text-sm);
				color: var(--text-grey);
			}
			& .row-section {
				display: flex;
				justify-content: space-between;
				& .radio-player {
					display: flex;
					gap: 16px;
					padding: 20px 0;
					width: 75%;
					& .radio-player-image {
			            width: 120px;
			            height: 120px;
			            animation: spin 30s infinite linear;
			        }

			        & .radio-deets-controls {
			        	& .name {}
			        	& .genres {
			        		margin: 0;
			        		font-size: var(--text-sm);
			        	}
			        	& .radio-controls {
				            padding: 16px 0;
				            display: flex;
				            flex-direction: row;
				            align-items: center;
				            justify-content: start;
				            gap: 16px;

				            & .skip-button {
				                cursor: pointer;

				            }

				            & .play-button, .stop-button {
				                padding: 6px 8px;
				                cursor: pointer;
				            }
			        	}
			        }
				}

				& .mini-channel-list {
					width: 25%;
					display: flex;
					flex-wrap: wrap;
					gap: 16px;
					padding: 20px 0;
					height: fit-content;
					.channel-thumbnail {
						width: 50px;
						height: 50px;
						margin: 0;
						border-radius: 4px;
						overflow: hidden;
						border: 0.5px solid var(--cyan-20);

						& img {
							width: 100%;
						}
					}
				}
			}
			
		}
		& .radio-updates-feed {
			margin: 20px 0;
			padding: 12px 40px;
			border-radius: 20px;
			background-color: var(--black-20);
			height: fit-content;
			.radio-channel-posts {

			}
		}
	}
	.poster {
      border-radius: 10px;
      overflow: hidden;
      width: 450px; /* Previously 300px, increased by 50% */
      height: auto;
      margin: 0 auto;
  }

  .poster img {
      width: 100%;
      border-radius: 10px;
      z-index: 1;
  }

  .radio-deets {
      text-align: center;
      color: white;
      width: 100%;
      margin-top: 12px;

      & h5 {
        margin: 12px auto 0;
      }

      & .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        margin-top: 12px;

        & .add-to-likes {
          margin: auto 0;
        }
        & .play-button {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          padding: 8px;
          border-radius: 4px;
          background-color: var(--black-20);
          width: fit-content;

          & p {
            font-size: var(--text-md);
            margin: 0;
            color: var(--cyan);
          }
        }
      }
  }
}

.radio-page {

  .radio-carousel-component {
    text-align: center;
    padding: 0 24px;
    max-width: 100%;
  }

  .slider-container {
    margin: 0 auto;
    position: relative;
    padding: 40px;
  }

  .carousel-container {
    max-width: 500px;
    position: relative;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform: scale(0.8);
    opacity: 0.5;
  }

  .slick-center .carousel-container {
    transform: scale(1);
    opacity: 1;
    z-index: 2;
    display: flex;
  }

  .poster {
    border-radius: 10px;
    overflow: hidden;
    width: 450px;
  }

  .poster img {
    width: 100%;
    border-radius: 10px;
    z-index: 1;
  }

  .radio-deets {
    text-align: center;
    color: white;
    width: 100%;
    margin-top: 12px;

    & h5 {
      margin: 12px auto 0;
    }

    & .action-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 16px;

      & .add-to-likes {
        margin: auto 0;
      }

      & .play-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        padding: 8px;
        border-radius: 4px;
        background-color: var(--black-20);
        width: fit-content;

        & p {
          font-size: var(--text-md);
          margin: 0;
          color: var(--cyan);
        }
      }
    }
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    z-index: 2;
  }

  .slick-center .overlay {
    display: none;
  }

  .slick-prev,
  .slick-next {
    z-index: 3;
  }

  .slick-prev:hover,
  .slick-next:hover {
    color: #555;
  }

  .slick-dots li button:before {
    color: #ccc;
  }

  .slick-dots li.slick-active button:before {
    color: #000;
  }
}