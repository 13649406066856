.terms-conditions {
    padding: 40px;
    & .submerge-logo {
        width: 150px;
        margin: 0 auto;
        & img {
            width: 100%;
        }
    }

    & .terms-conditions-content {
        padding: 24px 0;
        .heading {
            text-align: center;
        }

        .heading-date {
            text-align: center;
            font-size: var(--text-md);
            color: var(--text-grey);
        }

        & .main-content {
            .subheading {
                font-weight: 700;
            }

            .body {
                color: #ffffff90;
                text-wrap: wrap;
                font-size: var(--text-md);
                line-height: 24px;
                margin-bottom: 56px;

                ul, ol {
                    li {
                        color: #ffffff90;
                    }
                }
            }
            
        }
    }
}