body, html {
    overflow: visible !important;
}

.create-event-overlay {
	z-index: 1000 !important;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--black-50);
	overflow-y: scroll;
	padding: 24px;

	& .create-event-card {
		padding: 24px;
		width: 500px;
		margin: 2% auto auto;
		background-color: var(--dark-blue);
		border-radius: 10px;

		& .create-event-header {
			
			display: flex;
			justify-content: space-between;
			padding-bottom: 8px;

			.overlay-title {
				margin: 0;

			}

			.close-overlay-button {
				margin: auto 0;
			}
		}
	}

	.create-event-form {
		.input-fields-section {

			& .form-field {
				margin-bottom: 24px;
				padding-bottom: 0;

				.upload-thumbnail {
					display: flex;
					gap: 24px;
					justify-content: center;

					.thumbnail-preview {

						width: 160px;
						height: 160px;
						max-height: 160px;
						max-width: 160px;
						border-radius: 10px;

						& img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 10px;
							border: transparent;
						}
					}

					& .file-uploader {

						&:hover {
							cursor: pointer;
						}

						padding: 16px;
						border-radius: 4px;
						border: 1px dashed var(--acc-blue);
						justify-content: center;
						width: 125px;
						height: 125px;


						.uploader-icon {
							display: flex;
							justify-content: center;
							padding: 16px;
						}

						& .clickable-label, .file-name {
							color: var(--acc-blue);
							font-size: var(--text-sm);
							text-align: center;

							&:hover {
								cursor: pointer;
							}
						}

						.file-name {
							color: var(--text-grey);
						}
					}
				}

				.label {
					font-size: var(--text-sm);
					font-weight: bold;
					color: var(--text-grey);
					text-transform: uppercase;
				}

				& .input-field {
					background-color: transparent;
					border-top: 0;
					border-left: 0;
					border-right: 0;
					border-bottom: 1px solid var(--acc-blue);
					outline: transparent;
					font-size: var(--text-base);
					width: 92%;
					padding: 12px 16px 16px;
				}

				& .css-t3ipsp-control, .css-13cymwt-control {
					background-color: transparent !important;
					border-top: 0;
					border-left: 0;
					border-right: 0;
					border-bottom: 1px solid var(--acc-blue);
					border-radius: 0;
					color: white;
					&:focus {
						outline: none;
					}

					& input {
						color: white !important;
					} 
				}

				& .css-1nmdiq5-menu {
					background: var(--dark-blue);
				} 

				& .subtext {
					font-size: var(--text-sm);
					color: var(--faded-white);
				}

				& .datepicker-container {
					width: 100%;
					position: relative;

					.react-datepicker-wrapper {
						width: 100%;
						padding: 0 8px;
					}

					.calendar-icon {
						position: absolute;
						left: 0;
						bottom: 20px;
					}

					.react-datepicker__time-list {
						background-color: #fff; /* Ensure a visible background color */
						color: #000; /* Ensure the text color is visible */
					}
					  
					.react-datepicker__time-list-item {
					padding: 8px 12px; /* Adjust padding for better spacing */
					color: #000; /* Default text color */
					background-color: #fff; /* Default background color */
					cursor: pointer; /* Indicate clickable options */
					}
					  
					.react-datepicker__time-list-item:hover {
						background-color: #f0f0f0; /* Highlight option on hover */
					}
					  
					.react-datepicker__time-list-item--selected {
						background-color: #0094d4; /* Highlight selected option */
						color: #fff; /* Contrast selected text */
					}
				}

				& .post-content {
					padding: 8px 16px 0;
					font-size: var(--text-base);
					margin: auto 0;
				}

				& .pill-container {
					display: flex;
					flex-wrap: wrap;
					margin: 4px 0 12px;

					& .pill {
						width: fit-content;
						background-color: var(--ocean-blue);
						padding: 4px 10px;
						margin: 5px 5px 0;
						border-radius: 3px;
						display: flex;
						gap: 8px;
					}

					& .remove-performer-button {
						font-size: var(--text-md);
						margin: 2px 0 0;
						padding: 0;
					}
				}
			}
		}
		& .form-buttons {
			display: flex;
			justify-content: flex-end;
			gap: 16px;

			.button {
				width: 50%;
			}
		}
	}

}