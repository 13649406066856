.notification-popup {
	height: fit-content;
	
	& .container {
		background-color: var(--deep-blue-50);
		border-radius: 10px;
		padding: 12px;
		box-shadow: 0 0 22px -8px rgba(0,0,0,0.5);
		width: 400px;
		backdrop-filter: blur(10px);

		& .notification-row {
			display: flex;
			justify-content: space-between;
			align-items: center; /* Ensures consistent alignment */
			padding: 10px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Subtle divider */
			
			& .left-aligned-content {
				display: flex;
				align-items: center; /* Aligns thumbnail and text vertically */
				gap: 12px;

				& .notif-thumbnail {
					height: 50px;
					width: 50px;
					border-radius: 50%;
					overflow: hidden;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: var(--acc-blue);
				}

				& .notif-avatar {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
				
				& .notif-content {
					display: flex;
					flex-direction: column;
					justify-content: center;

					.notif-body {
						font-size: var(--text-md);
						color: var(--text-color);
						margin-bottom: 2px;
					}

					.notif-timestamp {
						font-size: var(--text-sm);
						color: var(--text-light);
					}
				}
			}

			& .right-aligned-content {
				display: flex;
				align-items: center;
				gap: 8px;

				& .new-notif-indicator {
					height: 10px;
					width: 10px;
					border-radius: 50%;
					background-color: var(--acc-blue);
				}
			}
		}

		& .notification-row:last-child {
			border-bottom: none;
		}
	}
}