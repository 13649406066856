.bubble-pill {
  -webkit-box-shadow: var(--bubble-pill-inset);
  -moz-box-shadow: var(--bubble-pill-inset);
  box-shadow: var(--bubble-pill-inset);
  border-radius: 15px;
  max-width: fit-content;
  padding: 4px 10px 8px;
  min-width: fit-content;
  cursor: pointer;

  .bubble-text {
    font-weight: bold;
    color: #419CFF; 
    margin-top: -1px;
    width: fit-content;
    font-size: var(--text-md);
  }
}