.secondary-button {
	margin: auto 0;

	.button {
		border: 1px solid var(--ocean-blue);
		border-radius: 20px;
		padding: 6px 16px;
		height: fit-content;
		width: 100%;
		font-size: var(--text-md);
		font-weight: bold;
		color: var(--ocean-blue);
	}
}