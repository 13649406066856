.message-prompt-modal {
	z-index: 99;
	position: absolute;
	background: rgba(0, 0, 0, .5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 60px;
	
	.prompt-container {
		font-size: var(--text-base) ;
		background: var(--dark-blue);
		padding: 16px;
		border-radius: 12px;
		width: 450px;
		margin: 10% auto;

		.prompt-title-text {
			text-align: center;
		}

		.prompt-body-text {
			padding: 8px 0;
			& p {
				width: 100%;
				text-align: center;
				line-height: 1.8;
				font-size: var(--text-md);
			}

		}

		.prompt-buttons {
			display: flex;
			gap: 8px;
			width: 100%;
			justify-content: end;

			& .btn {
				width: 20%;
			}
		}
	}
}