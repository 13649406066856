body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

/*  // <uniquifier>: Use a unique and descriptive class name*/
/*  // <weight>: Use a value from 100 to 900*/

  .noto-sans-regular {
    font-family: "Noto Sans", system-ui;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
  }
}

.bg-img {
  position: fixed;
  width: 100%;
  z-index: -99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .bg-img-file {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
  font-family: 'Noto Sans', sans-serif;
}

.zen-dots-regular {
  font-family: "Zen Dots", serif;
  font-weight: 400;
  font-style: normal;
}

h1 { font-size: 28px }
h2 { font-size: 20px }
h3 { font-size: 16px }
h4 { font-size: 14px }
h5 { font-size: 12px }
h6 { font-size: 10px }


