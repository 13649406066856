.events-page {
    padding: 80px 40px;
    & .events-page-hero {
        background-image: url('../assets/events-bg.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: fit-content;
        border-radius: 20px;

        padding: 24px;
        & .hero-texts {
            font-family: "Zen Dots", serif;

            & .events-header-text {
                font-size: 36px;
                font-weight: bold;
                /* color: var(--cyan-50); */
                margin: 0;
                text-align: center;
            }
        
            & .events-subheader-text {
                text-align: center;
                /* color: var(--cyan-50); */
            }
        }
    }

    & .all-events-list {
        & .all-events-header {
            padding: 0 0 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .eventpage-searchbar {
                padding: 0 8px;
                width: 26%;
                display: flex;
            }
            & .section-title-and-tabs {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 32px;

                .eventspage-tabs {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 24px;
                    margin-top: 8px;
    
                    & .tab {
                        padding: 0 8px 4px;

                        & .tab-underline {
                            height: 2px;
                        }

                        & .tabname {
                            font-size: var(--text-md);
                            font-weight: 600;
                        }
                    }
    
                    
                }
            }
            
        }

        & .all-events-content {
            display: flex;

            .events-results {
                width: 75%;

                & .all-events {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                }

                & .load-more-events {
                    margin: 32px auto 24px;
                    width: fit-content;
                }
            }

            

            & .events-side-panel {
                width: 25%;
                border: 1px solid var(--cyan-10);
                border-radius: 12px;
                padding: 12px 24px 24px;
                background-color: var(--black-10);
                height: fit-content;

                .panel-title {
                    font-weight: 800;
                    font-size: var(--text-md);
                }

                .events-filter-genre, 
                .events-filter-type, 
                .events-filter-location {
                    .label {
                        
                    }

                    .input-dropdown, .input-date {
                        background-color: transparent;
                        border-top: 0;
                        border-left: 0;
                        border-right: 0;
                        border-bottom: 1px solid var(--acc-blue);
                        outline: transparent;
                        font-size: var(--text-base);
                        width: 100%;
                        padding: 12px 16px 16px;
                    }
                }

                .filter-by-date {
                    & .event-page-datepicker {
                        border-radius: 0;
                        margin: 0 8px;
                        padding: 0;
                        background:none;
                        -webkit-appearance: none;
                        position: relative;
    
                        & .react-datepicker-wrapper {
                            width: 100%;
                        }

                        .datepicker {
                            background-color: transparent;
                            border-top: 0;
                            border-left: 0;
                            border-right: 0;
                            border-bottom: 1px solid var(--acc-blue);
                            outline: transparent;
                            font-size: var(--text-base);
                            width: 92%;
                            padding: 12px 16px 16px;
                        }
    
                        & .calendar-icon {
                            position: absolute;
                            left: 0;
                            bottom: 18px;
                        }
                    }
                }

                .input-event-filter-loc {
                    background-color: transparent;
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid var(--acc-blue);
                    outline: transparent;
                    font-size: var(--text-base);
                    width: 92%;
                    padding: 12px 16px 16px;
                }

                & .filter-button {
                    margin: 12px 4px 0;
                    display: flex;
                    justify-content: end;

                }
            }
        }
    }

}