.track-details-overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	padding: 16px;
    width: 500px;

    animation: fadeSlideInRight 0.3s ease-out forwards; /* Apply the animation */

	& .track-details-card {
		background-color: var(--deep-blue);
	    padding: 8px 16px 16px;
        border-radius: 12px;
        overflow: scroll;
		& .track-details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

		& .track-details-content {
			& .track-title {
				padding-top: 16px;
			}
			& .track-thumbnail {

				& img {
					width: 100%;
					height: 100%;
				}
			}

			& .artist-list {
				display: flex;
				flex-wrap: wrap;
				gap: 8px;
			}

			& .genres-list {
				display: flex;

				.genre-item {
					margin: 0;
					font-size: var(--text-sm);
					color: var(--text-grey);
				}
			}

			& h3 { 
				margin: 0 0 12px;
			}

			& .track-year {
				font-size: var(--text-md);
				font-weight: bold;
				color: var(--text-grey);
			}

			& .track-description {
				font-size: var(--text-md);
				color: var(--faded-white);
			}
		}
	}
}