.replies-section {
    width: 100%;
    margin-left: 20px;
    border-left: 3px solid var(--black-10); /* Visual separation */
    border-radius: 4px;
}

.single-reply {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    padding: 8px 16px;
    background-color: var(--black-05);
    position: relative;
}

.user-img {
    width: 40px;
    height: 40px;
    border-radius: 99px;
    overflow: hidden;
}

.user-img img {
    width: 100%;
}

.reply-by {
    display: block;
    width: 100%;
}

.name-and-dots {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
}

.with-timestamp {
    display: flex;
    align-items: center;
}

.reply-by-name {
    padding: 0;
}

.reply-by-date {
    padding: 2px 8px 0;
}

.reply-more-icon {
    position: relative;
}

.reply-options-modal {
    width: 160px;
    position: absolute;
    right: 2px;
    top: 20px;
    background-color: var(--dark-blue);
    box-shadow: 10px 0 20px -9px rgba(0,0,0,0.5);
    border-radius: 10px;
    overflow: hidden;
    z-index: 99;
}

.reply-options-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.reply-option-li {
    margin: 0;
    padding: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.reply-option-li:hover {
    background-color: var(--black-20);
}

.like-comment {
    display: flex;
    gap: 12px;
    position: absolute;
    right: 0;
    bottom: 0px; /* Adjusted for better alignment */
    padding: 8px 14px;
    border-radius: 20px 0 10px 0;
    background: rgba(255, 255, 255, 0.05);
}

.like-button {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;

    & .like-count {
        font-size: 12px;
        padding: 0;
    }
}
