.prim-button {
	& .button {
		background-color: var(--ocean-blue);
		border: 1px solid var(--ocean-blue);
		border-radius: 99px;
		padding: 8px 16px;
		height: fit-content;
		width: 100%;
		font-size: var(--text-sm);
		font-weight: bold;
	}
}

.button:disabled {
    color: lightgray;       /* Text color when disabled */
    opacity: 0.6;           /* Reduce opacity for a faded look */
}