.checkout-page {
    display: flex;
    align-items: center; 
    justify-content: center; 
    min-height: 100vh; 
    padding: 20px;
    background-color: var(--deep-blue-50); 
  }
  
  .checkout-content {
    width: 100%;
    max-width: 450px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
  }
  
  .checkout-header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }

.cart-items {
  max-height: 300px;
  overflow-y: auto;
  padding: 10px 0;
}

.cart-item {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.cart-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.cart-item-details {
  flex-grow: 1;
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 5px;
}

.quantity-control button {
  background-color: var(--acc-blue);
  border: none;
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  cursor: pointer;
}

.empty-cart {
  text-align: center;
  margin: 20px 0;
}

.checkout-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  border-top: 1px solid var(--deep-blue-100);
  margin-top: 10px;
}