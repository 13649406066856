.submit-event-banner {
    margin: 16px 40px;
    & .img-banner {
        /* background-image: url('../assets/bg-dark.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat; */
        background-color: var(--ocean-blue-10);
        height: 4vh;
        border-radius: 20px;
        padding: 32px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .submerge-logo {
            width: 150px;

            & img {
                width: 100%;
            }
        }

        & .texts {
            display: flex;
            flex-direction: column;
            & .banner-text {
                font-family: "Zen Dots", serif;
                font-size: var(--text-base);
                margin: 0 0 8px;
            }
            & .banner-cta {
                width: fit-content;
                align-self: flex-end;
            }
        }
        
    }
}