.privacy-policy {
    padding: 40px;
    & .submerge-logo {
        width: 150px;
        margin: 0 auto;
        & img {
            width: 100%;
        }
    }

    & .privacy-policy-content {
        padding: 24px 0;
        .heading {
            text-align: center;
        }

        .heading-date {
            text-align: center;
            font-size: var(--text-md);
            color: var(--text-grey);
        }

        & .main-content {
            padding: 40px 0;
            .subheading {
                font-weight: 700;
            }

            .subtext {
                font-weight: 700;
                font-size: var(--text-md);
                color: #ffffff90;
            }

            .emphasis {
                font-size: var(--text-md);
                color: #ffffff90;

                b {
                    color: #ffffff90;
                }
            }

            .body {
                color: #ffffff90;
                text-wrap: wrap;
                font-size: var(--text-md);
                line-height: 24px;
                margin-bottom: 24px;

                ul, ol {
                    li, i {
                        color: #ffffff90;
                    }
                }
            }

            table {
                width: 100%;
                table-layout: fixed;
                border-collapse: collapse;
                font-size: var(--text-md);
                tr {
                    th, td {
                        color: #ffffff90;
                        padding: 12px;
                        border: 1px solid #ffffff50;
                    }
                }
            }
            
        }
    }
}