& .radio-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 8px 16px;
    border-radius: 99px;
    cursor: pointer;
    width: 130px;
    height: 24px;

    border: 1px solid var(--cyan-20);
    background-color: var(--deep-blue-10);

    z-index: 99;
    position: fixed;
    top: 18px;
    right: 90px;

}

& .toggleRadioOff {
    
    & .switch-icon {
        width: 14px;
        & img {
            margin-top: 2px;
        }
    }

    & h4 { margin: 0; }
    & .text-def {
        
    }

    & .text-min{
        display: none;
    }
}