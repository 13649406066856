.share-post-modal {
	z-index: 99;
	position: absolute;
	background: rgba(0, 0, 0, .5);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 60px;
	
	.share-post-container {
		font-size: var(--text-base) ;
		background: var(--dark-blue);
		padding: 16px;
		border-radius: 12px;
		width: 600px;
		margin: 5% auto;

		.share-post-title-text {
			text-align: center;
		}

		.share-post-options {
			
			& .option {
				background-color: var(--black-10);
				margin-bottom: 8px;
				padding: 16px;
				border-radius: 10px;
				display: flex;
				gap: 16px;
				cursor: pointer;

				&:hover {
					background-color: var(--black-20);
				}

				.option-icon {
					max-width: 40px;
					max-height: 40px;
					width: 40px;
					height: 40px;
					& img {
						width: 100%;
						height: 100%;
					}
				}

				.option-texts {

					.option-title {
						padding: 0;
						font-size: var(--text-base);
						font-weight: bold;
					}

					.option-subtitle {
						padding: 2px 0 0;
						font-size: var(--text-md);
						color: var(--text-grey);
					}
				}
			}

			& .option-alt {
				background-color: var(--black-10);
				margin-bottom: 8px;
				padding: 16px;
				border-radius: 10px;
				gap: 16px;
				cursor: pointer;
				
				&:hover {
					background-color: var(--black-20);
				}

				.option-alt-flex {
					display: flex;
					gap: 16px;

					.option-icon {
						max-width: 40px;
						max-height: 40px;
						width: 40px;
						height: 40px;
						& img {
							width: 100%;
							height: 100%;
						}
					}

					.option-texts {

						.option-title {
							padding: 0;
							font-size: var(--text-base);
							font-weight: bold;
						}

						.option-subtitle {
							padding: 2px 0 0;
							font-size: var(--text-md);
							color: var(--text-grey);
						}
					}
				}

				.recipients {
					& .recipient-options {
						list-style: none;
						margin: 8px 0;
						padding: 4px;
						border-radius: 5px;
						border-radius: 4px;

						& .recipient-item {
							cursor: pointer;
							padding: 8px;
							display: flex;
							justify-content: space-between;

							/* Hide the default checkbox */
							.custom-checkbox input[type="checkbox"] {
								display: none;
							}

							/* Style the custom checkbox */
							.custom-checkbox .checkmark {
								width: 16px;
								height: 16px;
								margin: auto 0;
								border-radius: 4px;
								border: 1px solid var(--acc-blue);
								display: inline-block;
								position: relative;
								cursor: pointer;
								transition: background-color 0.3s ease;
							}

							/* Add a tick mark */
							.custom-checkbox .checkmark:after {
								content: "";
								position: absolute;
								display: none;
								left: 4px;
								top: 1px;
								width: 6px;
								height: 8px;
								border: solid white;
								border-width: 0 2px 2px 0;
								transform: rotate(45deg);
							}

							/* Change background when checked */
							.custom-checkbox input[type="checkbox"]:checked + .checkmark {
								background-color: var(--acc-blue);
							}

							/* Show the tick mark when checked */
							.custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
								display: block;
							}

							.recipient-deets {
								display: flex;
								gap: 8px;

								& .recipient-image {
									max-width: 20px;
									max-height: 20px;
									width: 20px;
									height: 20px;
									border-radius: 99px;
									margin: auto 0;
									& .recipient-userimage {
										width: 100%;
										height: 100%;
										border-radius: 99px;

									}
								}
								& .recipient-name {
									width: fit-content;
									padding: 0;
									margin: auto 0;
								}
							}
						}
					}
				}

				
			}

		}
	}
}