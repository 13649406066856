.upload-track-overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--black-50);
	overflow-y: scroll;
	padding: 24px;


	& .upload-track-card {
		padding: 24px;
		width: 500px;
		margin: 2% auto auto;
		background-color: var(--dark-blue);
		border-radius: 10px;

		& .upload-track-header {
			
			display: flex;
			justify-content: space-between;
			padding-bottom: 8px;

			.overlay-title {
				margin: 0;

			}

			.close-overlay-button {
				margin: auto 0;
			}
		}

		& .upload-track-form {
			& .input-fields-section {
				& .media-uploader {}
				& .form-field {

					margin-bottom: 24px;

					.required-asterisk {
						color: var(--red);
					}

					.upload-thumbnail {
						display: flex;
						gap: 24px;
						justify-content: center;

						.thumbnail-preview {

							width: 160px;
							height: 160px;
							max-height: 160px;
							max-width: 160px;
							border-radius: 10px;

							& img {
								width: 100%;
								height: 100%;
								object-fit: cover;
								border-radius: 10px;
								border: transparent;
							}
						}

						& .file-uploader {

							&:hover {
								cursor: pointer;
							}

							padding: 16px;
							border-radius: 4px;
							border: 1px dashed var(--acc-blue);
							justify-content: center;
							width: 125px;
							height: 125px;


							.uploader-icon {
								display: flex;
								justify-content: center;
								padding: 16px;
							}

							& .clickable-label, .file-name {
								color: var(--acc-blue);
								font-size: var(--text-sm);
								text-align: center;

								&:hover {
									cursor: pointer;
								}
							}

							.file-name {
								color: var(--text-grey);
							}
						}
					}

					.upload-audio {
						display: block;
						gap: 24px;

						& .audio-file {
							display: flex;
							gap: 8px;
							background-color: var(--black-10);
							padding: 8px 16px;
							border-radius: 8px;
							margin-bottom: 8px;


							.audio-file-name {
								color: var(--acc-blue);
								margin: 0;
							}
						}

						& .file-uploader {

							&:hover {
								cursor: pointer;
							} 
							margin-top: 12px;
							padding: 16px;
							border-radius: 4px;
							border: 1px dashed var(--acc-blue);
							justify-content: center;


							.uploader-icon {
								display: flex;
								justify-content: center;
							}

							& .clickable-label, .file-name {
								color: var(--acc-blue);
								font-size: var(--text-sm);
								text-align: center;
								&:hover {
									cursor: pointer;
								}
							}

							.file-name {
								color: var(--text-grey);
							}
						}

					}

					& .label {
						font-size: var(--text-sm);
						font-weight: bold;
						color: var(--text-grey);
						text-transform: uppercase;
						margin-bottom: 8px;
					}

					& .input-field {
						background-color: transparent;
						border-top: 0;
						border-left: 0;
						border-right: 0;
						border-bottom: 1px solid var(--acc-blue);
						outline: transparent;
						font-size: var(--text-base);
						width: 92%;
						padding: 12px 16px 16px;
					}

					& .subtext {
						font-size: var(--text-sm);
						color: var(--faded-white);
					}

					

					& .artists-tags {

						& .selected-artists-list {
							display: flex;
							padding: 0;
							flex-wrap: wrap;

							& .selected-tags {
								width: fit-content;
								background-color: var(--ocean-blue);
								padding: 4px 10px;
								margin: 5px 5px 0;
								border-radius: 3px;
								display: flex;
								gap: 8px;

							}
						}

						& .artist-tag {
							font-size: var(--text-sm);
							color: white;
							align-items: center;
							width: fit-content;
						}
					}

					& .artist-options {
						position: relative;
						

						& .artist-dropdown {
							list-style: none;
							margin: 8px 0;
							padding: 4px;
							border-radius: 5px;
							background-color: var(--dark-blue);
							border-radius: 4px;
							box-shadow: 0 0 22px -8px rgba(0,0,0,0.5);
							position: float;
							right: 0;
							left: 0;
							max-height: 150px;
							overflow-y: auto;
							z-index: 1;


							& .dropdown-item {
								cursor: pointer;
								padding: 8px;

							}

							& .dropdown-item:hover {
								background-color: var(--acc-blue);

							}
						}
					}

					.dropdown-genre {

						& .dropdown-genre:focus {
							outline: transparent;
							border: transparent; /* Remove border on focus */
							box-shadow: transparent; /* Optional: Remove any shadow effects */
						}

						width: 100%;
						padding: 12px 16px;
						font-size: var(--text-base);
						border-radius: 0;
						border-top: 0;
						border-left: 0;
						border-right: 0;
						border-bottom: 1px solid var(--acc-blue);
						background-color: transparent;
						color: white;
						appearance: none; /* Remove default arrow styling */
						-webkit-appearance: none;
						-moz-appearance: none;
						
						cursor: pointer;
						position: relative;
						transition: all 0.3s ease;
					}

				}
				& .form-buttons {
					display: flex;
					justify-content: flex-end;
					gap: 16px;

					.button {
						width: 50%;
					}
				}

				
			}
		}
	}
}