.loading-overlay {
    background-color: var(--black-50);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

.loading-graphic {
    display: grid;
    place-items: center;
    height: 100vh;
}
@keyframes ldio-x2uulkbinc {
    0% {
      top: 96px;
      left: 96px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 18px;
      left: 18px;
      width: 156px;
      height: 156px;
      opacity: 0;
    }
  }.ldio-x2uulkbinc div {
    position: absolute;
    border-width: 4px;
    border-style: solid;
    opacity: 1;
    border-radius: 50%;
    animation: ldio-x2uulkbinc 1s cubic-bezier(0,0.2,0.8,1) infinite;
  }.ldio-x2uulkbinc div:nth-child(1) {
    border-color: var(--cyan);
    animation-delay: 0s;
  }
  .ldio-x2uulkbinc div:nth-child(2) {
    border-color: var(--ocean-blue);
    animation-delay: -0.5s;
  }
  .loading-spinner-ripple {
    width: 200px;
    height: 200px;
    display: inline-block;
    overflow: hidden;
    background: transparent;
  }
  .ldio-x2uulkbinc {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-x2uulkbinc div { box-sizing: content-box; }