.shimmerprofile {
    min-width: 20%;
    background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
    background-size: 300%;
    background-position-x: 100%;
    animation: shimmer 1s infinite linear;
    border-radius: 10px;
    justify-content: center;
    padding: 16px;
    height: 400px;

    & .shimmer-circle {
        margin: 16px auto;
        width: 120px;
        height: 120px;
        min-width: 120px;
        min-height: 120px;
        background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
        background-size: 300%;
        background-position-x: 100%;
        animation: shimmer 1s infinite linear;
        border-radius: 99px;
    }

    & .shimmer-div {
        margin: 16px auto;
        height: 40px;
        min-height: 40px;
        background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
        background-size: 300%;
        background-position-x: 100%;
        animation: shimmer 1s infinite linear;
        border-radius: 10px;
    }

    & .shimmer-div-2 {
        margin: 16px auto;
        height: 20px;
        min-height: 20px;
        background: linear-gradient(-45deg, var(--black-10) 10%, var(--dark-blue-50) 50%, var(--black-10) 90%);
        background-size: 300%;
        background-position-x: 100%;
        animation: shimmer 1s infinite linear;
        border-radius: 10px;
    }

}

@keyframes shimmer {
    to {
       background-position-x: 0%
    }
 }