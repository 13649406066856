.create-message-modal {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--black-50);
	overflow-y: scroll;
	padding: 24px;

	& .inner-modal {
		padding: 16px 24px 24px;
		width: 500px;
		margin: 2% auto auto;
		background-color: var(--dark-blue);
		border-radius: 10px;

		.modal-header {
			display: flex;
			justify-content: space-between;

			.close-icon {
				margin: auto 0;
			}
		}

		& .recipient-tags {

			& .selected-recipient-list {
				display: flex;
				padding: 0;
				flex-wrap: wrap;

				& .selected-tags {
					width: fit-content;
					background-color: var(--main-blue);
					padding: 4px 10px;
					margin: 5px 5px 0;
					border-radius: 3px;
					display: flex;
					gap: 8px;

				}
			}

			& .recipient-tag {
				font-size: var(--text-sm);
				color: white;
				align-items: center;
				width: fit-content;
			}
		}

		& .recipient-options {
			position: relative;
			

			& .recipient-dropdown {
				list-style: none;
				margin: 8px 0;
				padding: 4px;
				border-radius: 5px;
				background-color: var(--dark-blue);
				border-radius: 4px;
				box-shadow: 0 0 22px -8px rgba(0,0,0,0.5);
				position: float;
				right: 0;
				left: 0;
				max-height: 150px;
				overflow-y: auto;
				z-index: 1;


				& .dropdown-item {
					cursor: pointer;
					padding: 8px;
					display: flex;
					gap: 8px;

					.user-image-container {
						width: 24px;
						height: 24px;
						max-width: 24px;
						max-height: 24px;
						border-radius: 99px;

						& .user-image-dropdown {
							height: 100%;
							width: 100%;
							object-fit: cover;
							border-radius: 99px;

						}
					}

				}

				& .dropdown-item:hover {
					background-color: var(--acc-blue);

				}
			}
		}

		.textarea {
			margin: 24px 0;
			box-shadow: 2px 0 20px -9px rgba(0,0,0,0.5);
			border-radius: 12px;
			width: 100%;

			.compose-message {
				font-size: var(--text-base);
				padding: 12px;

				height: calc(1.2em * 5);
				line-height: 1.2em;
			}
		}
	}
}