/** GENERAL STYLES **/
body {
    font-family: Arial, sans-serif;
    color: var(--text-grey);
}

.admin {
    background-color: var(--deep-blue-50); /* Matches Checkout Page */
    min-height: 100vh;
    display: flex;
    padding-top: 80px; /* Adjust based on Header height */
    background-image: none; /* Removes any conflicting background image */
}

/* Admin Main Container */
.admin-container {
    width: var(--container-width, 80%);
    padding: var(--spacing-md, 20px);
}

/* Admin Title */
.admin-title {
    margin-top: var(--spacing-md, 20px);
    margin-left: var(--spacing-md, 20px);
    font-size: var(--text-xl);
    font-weight: bold;
    color: var(--faded-white);
}

/** SIDEBAR **/
.admin-sidemenu {
    width: 250px;
    background-color: var(--dark-blue-50);
    padding: var(--spacing-md);
    min-height: 100vh;
}

.sidemenu-container h3 {
    color: var(--sand-beige);
    font-size: var(--text-lg);
    margin-bottom: var(--spacing-md);
}

.menu-list {
    margin-top: 10px;
}

.menu-item {
    padding: 10px;
    border-radius: var(--border-radius, 8px);
    cursor: pointer;
    color: var(--faded-white);
    transition: background-color 0.3s ease-in-out;
}

.menu-item:hover {
    background-color: var(--deep-blue-20);
}

.menu-item.active {
    background-color: var(--black-20);
}

/** EVENT SELECTOR **/
.event-selector {
    margin-bottom: var(--spacing-md);
}

.event-selector label {
    display: block;
    font-size: var(--text-md);
    margin-bottom: 5px;
    color: var(--sand-beige);
}

.event-selector select {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--black-20);
    border-radius: 5px;
    background-color: var(--black-10);
    font-size: var(--text-base);
    color: var(--faded-white);
    cursor: pointer;
}

/** ATTENDEES SECTION **/
.attendees-section {
    background-color: var(--black-10);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    margin-top: var(--spacing-md);
}

.attendees-table, .declines-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: var(--spacing-md);
}

.attendees-table th, .attendees-table td, .declines-table th, .declines-table td {
    border: 1px solid var(--black-20);
    padding: 8px;
    text-align: left;
    color: var(--faded-white);
}

.attendees-table th, .declines-table th {
    background-color: var(--black-20);
    font-weight: bold;
    font-size: var(--text-md);
}

.attendees-table tr:nth-child(even), .declines-table tr:nth-child(even) {
    background-color: var(--black-10);
}

.mention-link {
    color: var(--acc-blue); /* Use your theme color */
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}
  
.mention-link:hover {
color: var(--ocean-blue); /* Lighter blue on hover */
}

.declines-section {
background-color: var(--black-10);
padding: var(--spacing-md);
border-radius: var(--border-radius);
margin-top: var(--spacing-md);
}  
/** BUTTONS *
button {
    cursor: pointer;
    background-color: var(--acc-blue);
    border: none;
    padding: 10px 15px;
    border-radius: var(--border-radius);
    font-size: var(--text-md);
    color: white;
    transition: background 0.3s ease-in-out;
}

button:hover {
    background-color: var(--ocean-blue);
}
*/
/** RESPONSIVENESS **/
@media (max-width: 820px) {
    .admin {
        flex-direction: column;
        padding-top: 60px; /* Reduce padding for smaller screens */
    }

    .admin-sidemenu {
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 10px;
    }

    .admin-container {
        width: 100%;
    }

    .menu-list {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .menu-item {
        padding: 8px 12px;
    }
}

/** BUDGET SECTION **/
.budget-container {
    display: flex;
    flex-wrap: wrap; /* Ensures stacking when screen is narrow */
    justify-content: space-between;
    gap: var(--spacing-lg);
    padding: var(--spacing-md);
    background-color: var(--black-10);
    border-radius: var(--border-radius);
}
  
  /* Adjust width dynamically for responsiveness */
  .budget-section {
    flex: 1;
    min-width: 300px; /* Prevents shrinking too much */
    max-width: 48%; /* Keeps it from getting too wide */
    background-color: var(--dark-blue-50);
    padding: var(--spacing-md);
    border-radius: var(--border-radius);
    box-shadow: var(--shadow-light);
  }
  
  /* Ensure spacing between sections on smaller screens */
  @media (max-width: 900px) {
    .budget-section {
      max-width: 100%;
    }
  }
  
  /* Apply styles similar to event selector */
  .budget-actions {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--black-20);
    border-radius: 5px;
    background-color: var(--black-10);
    font-size: var(--text-base);
    color: var(--faded-white);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

/* Hover effect for consistency */
.budget-actions:hover,
.budget-actions:focus {
    background-color: var(--black-20);
}
  .budget-title {
    font-size: var(--text-lg);
    color: var(--sand-beige);
    margin-bottom: var(--spacing-md);
  }
  
  .budget-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: var(--spacing-md);
  }
  
  .budget-table th,
  .budget-table td {
    border: 1px solid var(--black-20);
    padding: 8px;
    text-align: left;
    color: var(--faded-white);
  }
  
  .budget-table th {
    background-color: var(--black-20);
    font-weight: bold;
  }
  
  .budget-table tr:nth-child(even) {
    background-color: var(--black-10);
  }
  
  .budget-input {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .budget-input input {
    padding: 8px;
    border: 1px solid var(--black-20);
    border-radius: var(--border-radius);
    background-color: var(--black-10);
    color: var(--faded-white);
    flex: 1;
    min-width: 150px; /* Prevents input fields from collapsing */
  }
  
  .budget-input button {
    padding: 10px;
    border: none;
    color: white;
    border-radius: var(--border-radius);
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    min-width: 80px;
  }
  
  .budget-input button:hover {
    opacity: 0.9;
  }

  /* Save & Cancel Buttons */
  .save-button, .cancel-button {
    padding: 10px;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 14px;
}
  
  .save-button {
    background-color: var(--green-500);
    color: white;
  }
  
  .cancel-button {
    background-color: var(--red-500);
    color: white;
  }
  
  /* Styling for Inputs */
  .budget-row input {
    width: 100%;
    padding: 5px;
    font-size: 14px;
    border: 1px solid var(--black-20);
    border-radius: 5px;
    background-color: var(--dark-blue-50); /* Darker background */
    color: var(--faded-white); /* Ensures text is visible */
}

/* Ensure text remains visible when focused */
.budget-row input:focus {
    outline: none;
    border-color: var(--acc-blue);
    background-color: var(--black-20);
    color: var(--faded-white);
}