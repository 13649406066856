:root {
  --deep-blue: #04385D;
  --deep-blue-50: rgba(4, 56, 93, 0.5);
  --deep-blue-20: rgba(4, 56, 93, 0.2);
  --deep-blue-10: rgba(4, 56, 93, 0.1);

  --ocean-blue: #1D599C;
  --ocean-blue-50: rgba(29, 89, 156, 0.5);
  --ocean-blue-20: rgba(29, 89, 156, 0.2);
  --ocean-blue-10: rgba(29, 89, 156, 0.1);

  --cyan: #00DFEB;
  --cyan-50: rgba(0, 223, 235, 0.5);
  --cyan-20: rgba(0, 223, 235, 0.2);
  --cyan-10: rgba(0, 223, 235, 0.1);

  --cliff-brown: #B58B4A;

  --sand-beige: #F4E3C1;
  --sand-beige-50: rgba(244, 227, 193, 0.5);
  --sand-beige-20: rgba(244, 227, 193, 0.2);
  --sand-beige-10: rgba(244, 227, 193, 0.1);

  --stone-grey: #A9A9A9;

  --black-05: rgba(0, 0, 0, 0.05);
  --black-10: rgba(0, 0, 0, 0.10);
  --black-20: rgba(0, 0, 0, 0.20);
  --black-50: rgba(0, 0, 0, 0.50);

  --main-blue: #1D599C;
  --header-bg: rgba(1,41,72,0.8);
  --header-bg: rgba(1,41,72,0.8);
  --text-grey: rgba(255, 255, 255, 0.5);
  --faded-white: rgba(255, 255, 255, 0.80);
  --red: #f47171;

  --dark-blue: #012948;
  --dark-blue-50: rgba(1, 41, 72, 0.5);
  --acc-blue: #0094D4;
  --acc-blue-50: rgba(0, 122, 255, 0.08);
  --bubble-pill-inset: inset 3px 9px 22px -9px rgba(0,123,255,1);

  --error: #FF7E7E;
  --success: #52A73A;

  --text-xxl: 28px;
  --text-xl: 24px;
  --text-lg: 20px;
  --text-base: 16px;
  --text-md: 14px;
  --text-sm: 12px;
  --text-xs: 10px;

  --gold: #F7B03E;
}

/* Keyframes for fade in and slide down */
@keyframes fadeSlideDown {
  0% {
    opacity: 0;          /* Fully transparent */
    transform: translateY(-50px); /* Positioned above */
  }
  100% {
    opacity: 1;          /* Fully visible */
    transform: translateY(0); /* At original position */
  }
}

/* Keyframes for fade in and slide up */
@keyframes fadeSlideUp {
  0% {
    opacity: 0;          /* Fully transparent */
    transform: translateY(50px); /* Positioned below */
  }
  100% {
    opacity: 1;          /* Fully visible */
    transform: translateY(0); /* At original position */
  }
}

/* Keyframes for fade in and slide up */
@keyframes fadeIn {
  0% {
    opacity: 0;          /* Fully transparent */
  }
  100% {
    opacity: 1;          /* Fully visible */
  }
}

@keyframes fadeSlideInRight {
  0% {
    opacity: 0;          /* Fully transparent */
    transform: translateX(50px); /* Positioned below */
  }
  100% {
    opacity: 1;          /* Fully visible */
    transform: translateX(0); /* At original position */
  }
}

@media (max-width: 820px) {

    :root {
      --text-xxl: 24px;
      --text-xl: 20px;
      --text-lg: 16px;
      --text-base: 14px;
      --text-md: 13px;
      --text-sm: 12px;
      --text-xs: 10px;
    }

    .user-image {
      min-width: 30px !important;
      width: 30px !important;
      max-height: 30px !important;
      border-radius: 100%;
      background-color: rgba(255,255,255,0.2);

      img {
        width: 30px !important;
        height: 30px !important;
        border-radius: 100%;

      }
    }
}

* span { font-size: var(--text-xs); }

* {
  color: rgba(255, 255, 255, 0.75);
}
* button { background-color: transparent; border: none; }
* hr {
  color: var(--acc-blue);
  opacity: 15%;
  border: 0.25px solid;
  margin: 16px 0;
}

.tab, button { cursor: pointer; }

Header {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--black-10);
}

.App {
  margin: 0 auto;

}

.component { padding: 8px 0; }

.user-image {
  min-width: 50px;
  width: 50px;
  max-height: 50px;
  border-radius: 100%;
  background-color: rgba(255,255,255,0.2);

  img {
    width: 50px;
    height: 50px;
    border-radius: 100%;

  }
}

textarea {
  background: transparent;
  border: none;
  overflow: auto;
  outline: none;
  resize: none;
  color: white;
  width: 100%;
  height: fit-content;  
  font-family: "Noto Sans", system-ui;
  font-size: var(--text-md);
}

.user-handle {
  font-weight: bold;
  width: fit-content;
}

button {
    cursor: pointer;
}

link {
  cursor: pointer;
}

.post-compose-box {
  padding: 16px 0 0;
  position: relative;
  font-size: var(--text-md);
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
