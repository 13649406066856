.radio-player-component-new {
    z-index: 50;
    height: fit-content;
    justify-content: center;
    position: fixed;
    top: 18px;
    right: 90px;
    width: fit-content;

    animation: fadeSlideInRight 0.3s ease-out forwards; /* Apply the animation */

    & .radio-player-container {
        position: relative;
        border-radius: 20px;
        border: 1px solid var(--cyan-10);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 2px 8px;
        justify-content: space-between;

        & .radio-details {
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        
        & .radio-player-image {
            width: 20px;
            height: 20px;
            min-width: 20px;
            min-height: 20px;
            animation: spin 30s infinite linear;
        }

        & .radio-music-info {
            padding: 0 16px;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;

            & p { margin: 0; }

            & .radio-channel { 
                display: flex;
                flex-direction: row;
                align-items: center;
                width: fit-content;

                & .radio-channel-name {
                    font-size: var(--text-sm); 
                    font-weight: bold;
                    color: var(--text-grey);
                }

                & .live-dot {
                    width: 6px;
                    height: 6px;
                    border-radius: 10px;
                    margin: auto 4px auto 0;
                    background-color: var(--red);
                }
            }

            & .radio-now-playing {
                padding: 4px 0;
                font-size: var(--text-base);

                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;

                & p { 
                    font-size: var(--text-base);
                    /* max-width: 10ch;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis; */
                }
            }
        }

        & .radio-controls {
            padding: 0 16px;

            /* background-color: var(--deep-blue-50); */
            border-radius: 0 0 20px 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 0;

            & .skip-button {
                cursor: pointer;

            }

            & .play-button, .stop-button {
                padding: 6px 8px;
                cursor: pointer;
                /* border-radius: 20px;
                background: linear-gradient(145deg, #012541, #012c4d);
                box-shadow:  5px 5px 12px #011d32,
                    -5px -5px 12px #01355e; */
            }
        }
    }
}

/* STYLE SCREEN IS <820px */
@media (max-width: 1024px) {
    .radio-player-component-new {
    
        & .radio-player-container {
    
            & .radio-details {
            }
            
            & .radio-player-image {
            }
    
            & .radio-music-info {
    
                & p { margin: 0; }
    
                & .radio-channel { 
    
                    & .radio-channel-name {
                        
                    }
    
                    & .live-dot {
                    }
                }
    
                & .radio-now-playing {
    
                    & p { 
                        max-width: 15ch;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
    
            & .radio-controls {
    
                & .skip-button {
                }
    
                & .play-button, .stop-button {
                }
            }
        }
    }
}

/* STYLE SCREEN IS <820px */
@media (max-width: 820px) {
    .radio-player-component-new {
    
        & .radio-player-container {
    
            & .radio-details {
            }
            
            & .radio-player-image {
            }
    
            & .radio-music-info {
    
                & p { margin: 0; }
                gap: 0;
    
                & .radio-channel { 
    
                    & .radio-channel-name {
                        
                    }
    
                    & .live-dot {
                    }
                }
    
                & .radio-now-playing {
    
                    & p, span { 
                        display: none;
                    }
                }
            }
    
            & .radio-controls {
    
                & .skip-button {
                }
    
                & .play-button, .stop-button {
                }
            }
        }
    }
}
