.event-details-modal {
    z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	overflow-y: scroll;
	padding: 16px;
    width: 500px;

    animation: fadeSlideInRight 0.3s ease-out forwards; /* Apply the animation */

    .event-details-content {
        background-color: var(--deep-blue);
	    padding: 8px 16px;
        border-radius: 12px;
        overflow: scroll;

        .event-details-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .event-date-posted {
            margin: 0 0 8px;
            font-size: var(--text-sm);
        }

        .event-date-loc {
            font-size: var(--text-md);
            margin: 8px 0 0;
            gap: 16px;

            & .sub-details {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
                padding-bottom: 4px;
                & p {
                    margin: 4px;
                    font-size: var(--text-md);
                    font-weight: bold;
                    text-align: center;
                }

                .event-date-loc-icon {
                    width: fit-content;
                }

                .performer-icon {
                    width: 10px;
                    padding-right: 4px;
                }
                
            }

            & .vertical-divider {
                width: 1px;
                background-color: var(--ocean-blue);
            }
        }

        .invite-response {
            display: flex;
            gap: 8px;
            margin: 8px 0;
            .response-option {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px 12px;
                gap: 8px;
                border: 1px solid var(--sand-beige);
                border-radius: 8px;
                width: 100%;
                justify-content: center;
                & p {
                    margin: 0;
                    color: var(--sand-beige);
                    font-size: var(--text-md);
                }
            }

            & .response-option:hover {
                background-color: var(--cyan-10);
            }
        }
    }
}