.create-mix-overlay {
	z-index: 99;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--black-50);
	height: 100vh;

	& .create-mix-card {
		padding: 24px;
		width: 500px;
		margin: 5% auto auto;
		background-color: var(--dark-blue);
		border-radius: 10px;

		& .create-mix-header {
			
			display: flex;
			justify-content: space-between;
			padding-bottom: 8px;

			.overlay-title {
				margin: 0;

			}

			.close-overlay-button {
				margin: auto 0;
			}
		}
	}

	.create-mix-form {
		.input-fields-section {

			.form-field {
				margin-bottom: 24px;

				.upload-thumbnail {
					display: flex;
					gap: 24px;
					justify-content: center;

					.thumbnail-preview {

						width: 160px;
						height: 160px;
						max-height: 160px;
						max-width: 160px;
						border-radius: 10px;

						& img {
							width: 100%;
							height: 100%;
							object-fit: cover;
							border-radius: 10px;
							border: transparent;
						}
					}

					& .file-uploader {

						&:hover {
							cursor: pointer;
						}

						padding: 16px;
						border-radius: 4px;
						border: 1px dashed var(--acc-blue);
						justify-content: center;
						width: 125px;
						height: 125px;


						.uploader-icon {
							display: flex;
							justify-content: center;
							padding: 16px;
						}

						& .clickable-label, .file-name {
							color: var(--acc-blue);
							font-size: var(--text-sm);
							text-align: center;

							&:hover {
								cursor: pointer;
							}
						}

						.file-name {
							color: var(--text-grey);
						}
					}
				}

				.label {
					font-size: var(--text-sm);
					font-weight: bold;
					color: var(--text-grey);
					text-transform: uppercase;
				}

				.input-field {
					background-color: transparent;
					border-top: 0;
					border-left: 0;
					border-right: 0;
					border-bottom: 1px solid var(--acc-blue);
					outline: transparent;
					font-size: var(--text-base);
					width: 92%;
					padding: 12px 16px 16px;
				}

				& .datepicker-container {
					width: 100%;
					position: relative;

					.react-datepicker-wrapper {
						width: 100%;
						padding: 0 8px;
					}

					.calendar-icon {
						position: absolute;
						left: 0;
						bottom: 20px;
					}
				}

				& .post-content {
					padding: 8px 16px 0;
					font-size: var(--text-base);
					margin: auto 0;
				}
			}
		}
		& .form-buttons {
			display: flex;
			justify-content: flex-end;
			gap: 16px;

			.button {
				width: 50%;
			}
		}
	}

}